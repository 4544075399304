import React from 'react';

import './Introduction.css'; 

const Introduction = ({ onSkip }) => {
  return (
    <div className="introduction-container">
      <h1>Welcome to Dracula Asks</h1>
      <p>
        Step into the shadows of Dracula’s castle, where every choice shapes your fate. In DraculaAsks, you will face a series of eerie, thought-provoking questions posed by Dracula himself. 
        Each answer will pull you deeper into his world—testing your courage, wisdom, and morality. Will you escape the castle unscathed, serve as his eternal servant, or perhaps… embrace the darkness as one of his own?
      </p>
      <p>
        Choose wisely, for every path leads to a chilling destiny. Are you ready to confront the darkness within? Your journey begins now.
      </p>
      

      <button className="skip-button" onClick={onSkip}>Proceed</button>
    </div>
  );
};

export default Introduction;











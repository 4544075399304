// src/components/AudioPlayer.js
import React, { useState, useRef, useEffect } from "react";
import musicFile from '../audio/music.mp3'; // Adjust the path to your music file

const AudioPlayer = () => {
  const [isPlaying, setIsPlaying] = useState(true); // To track if the music is playing or paused
  const audioRef = useRef(null); // Ref to the audio element

  // Toggle play/pause
  const togglePlayPause = () => {
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  useEffect(() => {
    // Automatically play the music when component mounts
    audioRef.current.play();
  }, []);

  return (
    <div style={{ position: "fixed", top: "10px", right: "10px" }}>
      <audio ref={audioRef} loop>
        {/* Source for the audio file */}
        <source src={musicFile} type="audio/mp3" />
        Your browser does not support the audio element.
      </audio>
      <button onClick={togglePlayPause} style={buttonStyles}>
        {isPlaying ? "Mute" : "Unmute"}
      </button>
    </div>
  );
};

const buttonStyles = {

  fontSize: "16px",
  borderRadius: "5px",
  cursor: "pointer",
  backgroundColor: "#ff4d4d",
  color: "#fff",
  border: "none",
  outline: "none",
};

export default AudioPlayer;

import React, { useEffect, useState } from "react";
import Path from "./components/pathData/path.js";
import "./App.css";
import DraculaAnimation from "./components/intro/DraculaAnimation";
import Introduction from "./components/introTwo/Introduction";
import Modal from '../src/components/model/Model.js'
import Allpath from "./components/allPaths/Allpath.js";

const paths = [
  // Your paths data
  {
    title: "The Philosopher’s Dilemma",
    description: "Delve into the intricate web of moral and existential questions in this thought-provoking path. As you confront dilemmas that challenge your beliefs and perceptions, you will engage with the essence of right and wrong, truth and deception. Each choice will guide you deeper into the labyrinth of the human psyche, testing your philosophical resolve and shaping your fate in unexpected ways..",
    questions: [
      {
        text: "What do you believe is the purpose of life?",
        options: [
          { text: "To seek happiness", next: 1 },
          { text: "To gain knowledge", next: 2 },
          { text: "To serve others", next: 3 },
          { text: "There is no purpose",  outcomes: [11]}
        ],
      },
      {
        text: "What do you fear most?",
        options: [
          { text: "Death", next: 4 },
          { text: "Pain", next: 4 },
          { text: "The Unknown", next: 2 },
          { text: "Nothing", outcomes: [12] }, //you are dead
        ],
      },
      {
        text: "What do you view time?",
        options: [
          { text: "Aa a precious resource", next: 5 },
          { text: "As an illusion", next: 6 },
          { text: "As a cycle", next: 3 },
          { text: "As a burden", outcomes: [12] }, //you are dead
        ],
      },
      {
        text: "What is more valuable : Freedom or security?",
        options: [
          { text: "Freedom", next: 4 },
          { text: "Security", next: 7 },
          { text: "Both are essential", next: 6 },
          { text: "Neither matters to me", outcomes: [11] }, // you escape the place
        ],
      },
      {
        text: "What do you think of love?",
        options: [
          { text: "Its the most powerful force", next: 5 },
          { text: "its an illusion", next: 8 },
          { text: "Its a feeling", next: 7 },
          { text: "It's a weakness", outcomes: [12] }, // you are dead
        ],
      },
      {
        text: "Would you sacrifice your morals for survival?",
        options: [
          { text: "Yes,I would", next: 6 },
          { text: "No, I Would't", next: 9 },
          { text: "It depends on the situation", next: 7 },
          { text: "Survival is not worth it", outcomes: [12] }, // you are dead
        ],
      },
      {
        text: "Do you believe in in destiny ?",
        options: [
          { text: "Yes, I do", next: 8 },
          { text: "No, I don't", next: 9 },
          { text: "I'm unsure", outcomes: [14]}, //you are to live in the place as darculas servant for eternity
          { text: "Destiny is a lie",outcomes: [11] }, // you escape the place
        ],
      },
      {
        text: "What would you do to gain power?",
        options: [
          { text: "Anything", next: 8 },
          { text: "Only what is right", next: 9 },
          { text: "i dont seek power", outcomes: [14] }, //you are to live in the place as darculas servant for eternity
          { text: "I'd give up everything",outcomes: [11] }, // you escape the place
        ],
      },
      {
        text: "Do you believe in the soul?",
        options: [
          { text: "Yes,It's eternal", outcomes: [13]}, // you become a vampire serving darcula
          { text: "No, It's a myth", outcomes: [12]}, // you are dead
          { text: "I'm unsure", next: 9 },
          { text: "It doesn't matter to me", outcomes: [11]}, // you escape the place
        ],
      },
      {
        text: "Will you choose eternal life or a mortal death?",
        options: [
          { text: "eternal life", outcomes: [13] },  // you become a vampire serving darcula
          { text: "Mortal death", outcomes: [12] },   // you are dead
          { text: "I choose to serve you",outcomes: [14] }, // you are to live in the place as darculas servents for eternity
          { text: "I choose to escape", outcomes: [11] },  // you escape the place
        ],
      },   
    ],
  },
//path2
  {
    title: "The Warrior’s Challenge",
    description: "Step into the shoes of a valiant warrior facing trials of strength, honor, and sacrifice. This path is fraught with physical and mental challenges that will push your limits. Confront questions about courage, loyalty, and the cost of victory. Your decisions will determine whether you emerge as a legendary hero, a fallen soldier, or something far darker, forever bound to the castle’s shadows..",
    questions: [
      {
        text: "What do you value most in life?",
        options: [
          { text: "Strength", next: 1 },
          { text: "Honor", next: 2 },
          { text: "Wisdom", next: 3 },
          { text: "Freedom",outcomes: [11] }, // Outcome: You Escape the Palace
        ],
      },
      {
        text: "What would you fight for?",
        options: [
          { text: "Glory", next: 4 },
          { text: "Survival", next: 5 },
          { text: "Justice", next: 2 },
          { text: "Nothing",outcomes: [12] }, // Outcome: You are Dead
        ],
      },
      {
        text: "What do you fear losing?",
        options: [
          { text: "My strength", next: 5 },
          { text: "My honor", next: 6 },
          { text: "My mind",outcomes: [12] }, // Outcome: You are Dead
        ],
      },
      {
        text: "What is more important: victory or sacrifice?",
        options: [
          { text: "Victory", next: 4 },
          { text: "Sacrifice", next: 7 },
          { text: "Both", next: 6 },
          { text: "Neither matters", outcomes: [11] }, // Outcome: You Escape the Palace
        ],
      },
      {
        text: "What do you think of war?",
        options: [
          { text: "It’s necessary", next: 5 },
          { text: "It’s evil", next: 8 },
          { text: "It’s inevitable", next: 7 },
          { text: "It’s pointless",outcomes: [12] }, // Outcome: You are Dead
        ],
      },
      {
        text: "Would you betray your allies to survive?",
        options: [
          { text: "Yes, if I must", next: 6 },
          { text: "No, never", next: 9 },
          { text: "It depends", next: 7 },
          { text: "I’d rather die", outcomes: [12] }, // Outcome: You are Dead
        ],
      },
      {
        text: "What is the greatest enemy?",
        options: [
          { text: "Fear", next: 8 },
          { text: "Pride", next: 9 },
          { text: "Ignorance", outcomes: [14]  }, // Outcome: You are to live in the palace as Dracula's servant for eternity
          { text: "Weakness",outcomes: [11] }, // Outcome: You Escape the Palace
        ],
      },
      {
        text: "What do you seek in battle?",
        options: [
          { text: "Victory", next: 8 },
          { text: "Honor", next: 9 },
          { text: "Survival", outcomes: [14]  }, // Outcome: You are to live in the palace as Dracula's servant for eternity
          { text: "Peace", outcomes: [11] }, // Outcome: You Escape the Palace
        ],
      },
      {
        text: "What do you think of mercy?",
        options: [
          { text: "It’s for the weak",  outcomes: [13] }, // Outcome: You become a Vampire serving Dracula
          { text: "It’s a virtue", outcomes: [12] }, // Outcome: You are Dead
          { text: "It’s necessary", next: 9 },
          { text: "It’s dangerous",outcomes: [11] }, // Outcome: You Escape the Palace
        ],
      },
      {
        text: "Will you choose strength or loyalty?",
        options: [
          { text: "Strength", outcomes: [13] }, // Outcome: You become a Vampire serving Dracula
          { text: "Loyalty", outcomes: [14]  }, // Outcome: You are to live in the palace as Dracula's servant for eternity
          { text: "Both",  outcomes: [13]  }, // Outcome: You become a Vampire serving Dracula
          { text: "Neither",  outcomes: [11] }, // Outcome: You Escape the Palace
        ],
      },
    ],
  },
//path3
  {
    title: "The Mystic’s Journey",
    description: "Embark on an enchanting voyage through the realms of magic, spirituality, and the unknown. In this mystical path, you will grapple with profound questions about fate, destiny, and the unseen forces that govern the universe. Your answers will either unveil the secrets of the cosmos or ensnare you in a web of enchantment from which there is no escape..",
    questions: [
      {
        text: "Do you believe in the supernatural?",
        options: [
          { text: "Yes, absolutely.", next: 1 },
          { text: "No, I’m a skeptic.", next: 2 },
          { text: "I’m open to the possibility.", next: 3 },
          { text: "It’s nonsense.", outcomes: [11] }, // Outcome: You Escape the Palace
        ],
      },
      {
        text: "What is the nature of the soul?",
        options: [
          { text: "It’s eternal.", next: 4 },
          { text: "It’s a construct.", next: 5 },
          { text: "It’s a mystery.", next: 2 },
          { text: "It doesn’t exist.", outcomes: [12] }, // Outcome: You are Dead
        ],
      },
      {
        text: "Do you believe in destiny?",
        options: [
          { text: "Yes, I do.", next: 5 },
          { text: "No, I don’t.", next: 6},
          { text: "It’s possible.", nexoutcomes: [12] }, // Outcome: You are Dead
        ],
      },
      {
        text: "What is your greatest fear?",
        options: [
          { text: "The unknown.", next: 4 },
          { text: "Death.", next: 7 },
          { text: "Failure.", next: 6 },
          { text: "Nothing.",outcomes: [11] }, // Outcome: You Escape the Palace
        ],
      },
      {
        text: "What do you seek in life?",
        options: [
          { text: "Enlightenment.", next: 5 },
          { text: "Power.", next: 8 },
          { text: "Pleasure.", next: 7 },
          { text: "Freedom.",outcomes: [11] }, // Outcome: You Escape the Palace
        ],
      },
      {
        text: "What would you sacrifice for knowledge?",
        options: [
          { text: "Anything.", next: 6 },
          { text: "Nothing.", next: 9 },
          { text: "My time.", next: 7 },
          { text: "My morals.", outcomes: [12] }, // Outcome: You are Dead
        ],
      },
      {
        text: "What do you think of immortality?",
        options: [
          { text: "It’s a gift.", next: 8 },
          { text: "It’s a curse.", next: 9 },
          { text: "It’s a mystery.",outcomes: [14] }, // Outcome: You are to live in the palace as Dracula's servant for eternity
          { text: "I don’t desire it.",outcomes: [11] }, // Outcome: You Escape the Palace
        ],
      },
      {
        text: "What do you think of magic?",
        options: [
          { text: "It’s real.", next: 8 },
          { text: "It’s an illusion.", next: 9 },
          { text: "It’s dangerous.", outcomes: [14]}, // Outcome: You are to live in the palace as Dracula's servant for eternity
          { text: "It’s nonsense.", outcomes: [11] }, // Outcome: You Escape the Palace
        ],
      },
      {
        text: "What is your view of the afterlife?",
        options: [
          { text: "It’s real.",  outcomes: [13]}, // Outcome: You become a Vampire serving Dracula
          { text: "It’s a myth.", outcomes: [12] }, // Outcome: You are Dead
          { text: "It’s unknowable.", next: 9 },
          { text: "It’s irrelevant.", outcomes: [11] }, // Outcome: You Escape the Palace
        ],
      },
      {
        text: "Will you choose knowledge or power?",
        options: [
          { text: "Knowledge.",outcomes: [14]}, // Outcome: You are to live in the palace as Dracula's servant for eternity
          { text: "Power.", outcomes: [13]}, // Outcome: You become a Vampire serving Dracula
          { text: "Both.",  outcomes: [13]}, // Outcome: You become a Vampire serving Dracula
          { text: "Neither.", outcomes: [11]}, // Outcome: You Escape the Palace
        ],
      },
    ],
  },
//path4 
  {
    title: "The Lover’s Temptation",
    description: "Immerse yourself in the intoxicating allure of love, desire, and passion. This path invites you to explore the complexities of relationships and the heart’s yearnings. As you confront questions of loyalty, betrayal, and sacrifice, your choices w.",
    questions: [
      {
        text: "What is the meaning of love?",
        options: [
          { text: "It’s the essence of life.", next: 1 },
          { text: "It’s an illusion.", next: 2 },
          { text: "It’s a powerful force.", next: 3 },
          { text: "It’s a weakness.",outcomes: [11] }, // Outcome: You Escape the Palace
        ],
      },
      {
        text: "What would you do for love?",
        options: [
          { text: "Anything.", next: 4 },
          { text: "Nothing.", next: 5 },
          { text: "Sacrifice everything.", next: 2 },
          { text: "I don’t believe in it.", outcomes: [12] }, // Outcome: You are Dead
        ],
      },
      {
        text: "Do you believe in eternal love?",
        options: [
          { text: "Yes, I do.", next: 5 },
          { text: "No, I don’t.", next: 6 },
          { text: "It’s possible.", next: 3 },
          { text: "It’s a fantasy.",outcomes: [12] }, // Outcome: You are Dead
        ],
      },
      {
        text: "What is your greatest desire?",
        options: [
          { text: "Love.", next: 4 },
          { text: "Power.", next: 7 },
          { text: "Freedom.", next: 6 },
          { text: "Wealth.", outcomes: [11] }, // Outcome: You Escape the Palace
        ],
      },
      {
        text: "Would you die for love?",
        options: [
          { text: "Yes, I would.", next: 5 },
          { text: "No, I wouldn’t.", next: 8 },
          { text: "It depends on the situation.", next: 7 },
          { text: "Love is not worth dying for.", outcomes: [12] }, // Outcome: You are Dead
        ],
      },
      {
        text: "What do you think of passion?",
        options: [
          { text: "It’s essential.", next: 6 },
          { text: "It’s dangerous.", next: 9 },
          { text: "It’s fleeting.", next: 7 },
          { text: "It’s overrated.",outcomes: [12] }, // Outcome: You are Dead
        ],
      },
      {
        text: "What would you sacrifice for love?",
        options: [
          { text: "Anything.", next: 8 },
          { text: "Nothing.", next: 9 },
          { text: "My life.", outcomes: [14] }, // Outcome: You are to live in the palace as Dracula's servant for eternity
          { text: "Love is not worth it.",outcomes: [11] }, // Outcome: You Escape the Palace
        ],
      },
      {
        text: "What do you think of eternal life?",
        options: [
          { text: "It’s desirable.", next: 8 },
          { text: "It’s a curse.", next: 9 },
          { text: "It’s a fantasy.", outcomes: [14] }, // Outcome: You are to live in the palace as Dracula's servant for eternity
          { text: "I don’t desire it.",outcomes: [11] }, // Outcome: You Escape the Palace
        ],
      },
      {
        text: "Do you believe in fate?",
        options: [
          { text: "Yes, it’s real.",  outcomes: [13]}, // Outcome: You become a Vampire serving Dracula
          { text: "No, it’s a myth.", outcomes: [12] }, // Outcome: You are Dead
          { text: "It’s possible.", next: 9 },
          { text: "It doesn’t matter.", outcomes: [11] }, // Outcome: You Escape the Palace
        ],
      },
      {
        text: "Will you choose love or power?",
        options: [
          { text: "Love.", outcomes: [14]}, // Outcome: You are to live in the palace as Dracula's servant for eternity
          { text: "Power.", outcomes: [13]}, // Outcome: You become a Vampire serving Dracula
          { text: "Both.", outcomes: [13]}, // Outcome: You become a Vampire serving Dracula
          { text: "Neither.",outcomes: [11] }, // Outcome: You Escape the Palace
        ],
      },
    ],
  },
//path5 -- need to add an option here
  {
    title: "The Scholar’s Test",
    description: "Enter the hallowed halls of knowledge and inquiry as a devoted scholar. This path challenges your intellect and curiosity with questions that delve into history, philosophy, and the pursuit of truth. Each decision you make will reflect your thirst for knowledge and wisdom, ultimately determining whether you are celebrated as a learned sage or lost in obscuritity",
    questions: [
      {
        text: "What is the greatest virtue?",
        options: [
          { text: "Wisdom.", next: 1 },
          { text: "Courage.", next: 2 },
          { text: "Justice.", next: 4 }, // Outcome: You Escape the Palace
          { text: "None of these", outcomes: [11] },
        ],
      },
      {
        text: "What do you seek in knowledge?",
        options: [
          { text: "Truth.", next: 4 },
          { text: "Power.", next: 5 },
          { text: "Understanding.", next: 2 },
          { text: "Control.", outcomes: [12] }, // Outcome: You are Dead
        ],
      },
      {
        text: "What do you fear losing?",
        options: [
          { text: "My mind.", next: 5 },
          { text: "My freedom.", next: 6 },
          { text: "My life.",outcomes: [12] }, // Outcome: You are Dead
        ],
      },
      {
        text: "What is more important: wisdom or power?",
        options: [
          { text: "Wisdom.", next: 4 },
          { text: "Power.", next: 7 },
          { text: "Both.", next: 6 },
          { text: "Neither.",outcomes: [11] }, // Outcome: You Escape the Palace
        ],
      },
      {
        text: "What do you think of truth?",
        options: [
          { text: "It’s absolute.", next: 5 },
          { text: "It’s relative.", next: 8 },
          { text: "It’s elusive.", next: 7 },
          { text: "It’s a lie.", outcomes: [12] }, // Outcome: You are Dead
        ],
      },
      {
        text: "Would you sacrifice morality for knowledge?",
        options: [
          { text: "Yes, if necessary.", next: 6 },
          { text: "No, never.", next: 9 },
          { text: "It depends.", next: 7 },
          { text: "Knowledge is not worth it.",outcomes: [12] }, // Outcome: You are Dead
        ],
      },
      {
        text: "What do you think of immortality?",
        options: [
          { text: "It’s desirable.", next: 8 },
          { text: "It’s a curse.", next: 9 },
          { text: "It’s a mystery.",outcomes: [14] }, // Outcome: You are to live in the palace as Dracula's servant for eternity
          { text: "I don’t desire it.",outcomes: [11] }, // Outcome: You Escape the Palace
        ],
      },
      {
        text: "What do you think of the unknown?",
        options: [
          { text: "It’s to be explored.", next: 8 },
          { text: "It’s to be feared.", next: 9 },
          { text: "It’s dangerous.",outcomes: [14]}, // Outcome: You are to live in the palace as Dracula's servant for eternity
          { text: "It’s irrelevant.",outcomes: [11] }, // Outcome: You Escape the Palace
        ],
      },
      {
        text: "What is the purpose of knowledge?",
        options: [
          { text: "To gain power.",  outcomes: [13]}, // Outcome: You become a Vampire serving Dracula
          { text: "To understand the world.",outcomes: [14]}, // Outcome: You are to live in the palace as Dracula's servant for eternity
          { text: "To help others.", next: 9 },
          { text: "To escape ignorance.",outcomes: [11] }, // Outcome: You Escape the Palace
        ],
      },
      {
        text: "Will you choose wisdom or power?",
        options: [
          { text: "Wisdom.",outcomes: [14] }, // Outcome: You are to live in the palace as Dracula's servant for eternity
          { text: "Power." ,outcomes: [13]}, // Outcome: You become a Vampire serving Dracula
          { text: "Both.",  outcomes: [13] }, // Outcome: You become a Vampire serving Dracula
          { text: "Neither.", outcomes: [11] }, // Outcome: You Escape the Palace
        ],
      },
    ],
  },
//path6 
  {
    title: "The Wanderer’s Trial",
    description: "Join the ranks of those who roam the world in search of meaning and adventure. In this path, you will encounter questions about identity, belonging, and the spirit of exploration. Your choices will guide your journey, revealing whether you find a place to call home or remain a restless wanderer forever seeking solace in the unknown",
    questions: [
      {
        text: "What drives you forward in life?",
        options: [
          { text: "Adventure.", next: 1 },
          { text: "Survival.", next: 2 },
          { text: "Curiosity.", next: 3 },
          { text: "Fear of the unknown.",outcomes: [11]}, // Outcome: You Escape the Palace
        ],
      },
      {
        text: "Do you fear change?",
        options: [
          { text: "Yes.", next: 4 },
          { text: "No.", next: 5 },
          { text: "I embrace it.", next: 2 },
          { text: "I resist it.", outcomes: [14] }, // Outcome: You are to live in the palace as Dracula's servant for eternity
        ],
      },
      {
        text: "What is the greatest challenge for a wanderer?",
        options: [
          { text: "Loneliness.", next: 4 },
          { text: "Danger.", next: 6 },
          { text: "Lack of purpose.", next: 5 },
          { text: "The unknown.", outcomes: [11] }, // Outcome: You Escape the Palace
        ],
      },
      {
        text: "What would you sacrifice for safety?",
        options: [
          { text: "Freedom.", next: 5 },
          { text: "Nothing.", next: 7 },
          { text: "My pride.", next: 6 },
          { text: "I’ll do anything.",outcomes: [14] }, // Outcome: You are to live in the palace as Dracula's servant for eternity
        ],
      },
      {
        text: "Is there a place you wish to call home?",
        options: [
          { text: "Yes.", next: 5 },
          { text: "No.", next: 8 },
          { text: "I haven't found it yet.", next: 6 },
          { text: "Home is an illusion.", outcomes: [11] }, // Outcome: You Escape the Palace
        ],
      },
      {
        text: "What is the price of freedom?",
        options: [
          { text: "Solitude.", next: 7 },
          { text: "Responsibility.", next: 8 },
          { text: "Sacrifice.", next: 9 },
          { text: "It’s priceless.",outcomes: [12] }, // Outcome: You are Dead
        ],
      },
      {
        text: "Do you trust strangers?",
        options: [
          { text: "No.", next: 9 },
          { text: "Only sometimes.", next: 9 },
          { text: "Yes, if needed.",outcomes: [14] }, // Outcome: You are to live in the palace as Dracula's servant for eternity
          { text: "Trust is earned.",outcomes: [11] }, // Outcome: You Escape the Palace
        ],
      },
      {
        text: "Is survival worth any cost?",
        options: [
          { text: "Yes.", next: 8 },
          { text: "No.", next: 9 },
          { text: "It depends.",  outcomes: [13] }, // Outcome: You become a Vampire serving Dracula
          { text: "Survival is secondary.", outcomes: [11] }, // Outcome: You Escape the Palace
        ],
      },
      {
        text: "Will you keep wandering or stay?",
        options: [
          { text: "I will wander.",outcomes: [11]}, // Outcome: You Escape the Palace
          { text: "I will stay.",outcomes: [14] }, // Outcome: You are to live in the palace as Dracula's servant for eternity
          { text: "I want both.",  outcomes: [13] }, // Outcome: You become a Vampire serving Dracula
          { text: "I don’t know.",outcomes: [12]  }, // Outcome: You are Dead
        ],
      },
      {
        text: "Will you choose adventure or security?",
        options: [
          { text: "Adventure.", outcomes: [11]}, // Outcome: You Escape the Palace
          { text: "Security.", outcomes: [14] }, // Outcome: You are to live in the palace as Dracula's servant for eternity
          { text: "Both.",  outcomes: [13]}, // Outcome: You become a Vampire serving Dracula
          { text: "Neither.", outcomes: [12]}, // Outcome: You are Dead
        ],
      },
    ],
  },
//path7
  {
    title: "The Philosopher’s Quandary",
    description: "Engage with the eternal questions that have puzzled humanity since time immemorial. This path presents a series of philosophical dilemmas that force you to confront your values and beliefs. As you navigate through profound inquiries about existence and morality, your answers will dictate whether you attain enlightenment or succumb to the abyss of uncertainty.",
    questions: [
      {
        text: "What is the meaning of life?",
        options: [
          { text: "To seek wisdom.", next: 1 },
          { text: "To find happiness.", next: 2 },
          { text: "To serve others.", next: 3 },
          { text: "There is no meaning.", outcomes: [12] }, // Outcome: You are Dead
        ],
      },
      {
        text: "Can knowledge lead to corruption?",
        options: [
          { text: "Yes, power corrupts.", next: 4 },
          { text: "No, knowledge is pure.", next: 5 },
          { text: "It depends on the person.",outcomes: [12]}, // Outcome: You are Dead
        ],
      },
      {
        text: "Do you believe in fate?",
        options: [
          { text: "Yes.", next: 5 },
          { text: "No.", next: 6 },
          { text: "I’m uncertain.", next: 3 },
          { text: "Fate is a myth.", outcomes: [11]}, // Outcome: You Escape the Palace
        ],
      },
      {
        text: "What is more important: morality or survival?",
        options: [
          { text: "Morality.", next: 4 },
          { text: "Survival.", next: 7 },
          { text: "Both are important.", next: 6 },
          { text: "Neither.",outcomes: [12]}, // Outcome: You are Dead
        ],
      },
      {
        text: "Would you sacrifice wisdom for peace?",
        options: [
          { text: "Yes, peace is better.", next: 5 },
          { text: "No, wisdom is paramount.", next: 8 },
          { text: "It depends.", next: 7 },
          { text: "Peace is a lie.", outcomes: [12] }, // Outcome: You are Dead
        ],
      },
      {
        text: "What is the greatest danger in seeking knowledge?",
        options: [
          { text: "Losing oneself.", next: 6 },
          { text: "Uncovering dark truths.", next: 9 },
          { text: "Becoming arrogant.", next: 7 },
          { text: "There is no danger.", outcomes: [12] }, // Outcome: You are Dead
        ],
      },
      {
        text: "Do you believe wisdom or power is more valuable?",
        options: [
          { text: "Wisdom.", next: 8 },
          { text: "Power.", next: 9 },
          { text: "Both.",  outcomes: [13] }, // Outcome: You become a Vampire serving Dracula
          { text: "Neither.",outcomes: [14] }, // Outcome: You are to live in the palace as Dracula's servant for eternity
        ],
      },
      {
        text: "Is freedom worth any cost?",
        options: [
          { text: "Yes, freedom is everything.", next: 8 },
          { text: "No, some things matter more.", next: 9 },
          { text: "It depends on the price.",  outcomes: [13] }, // Outcome: You become a Vampire serving Dracula
          { text: "Freedom is an illusion.", outcomes: [11] }, // Outcome: You Escape the Palace
        ],
      },
      {
        text: "Will you choose knowledge or happiness?",
        options: [
          { text: "Knowledge.", outcomes: [13] }, // Outcome: You become a Vampire serving Dracula
          { text: "Happiness.",outcomes: [14]}, // Outcome: You are to live in the palace as Dracula's servant for eternity
          { text: "Both.", outcomes: [11]}, // Outcome: You Escape the Palace
          { text: "Neither.", outcomes: [12]}, // Outcome: You are Dead
        ],
      },
      {
        text: "Is power worth more than love?",
        options: [
          { text: "Yes.", outcomes: [13] }, // Outcome: You become a Vampire serving Dracula
          { text: "No.",outcomes: [14] }, // Outcome: You are to live in the palace as Dracula's servant for eternity
          { text: "I want both.",  outcomes: [13] }, // Outcome: You become a Vampire serving Dracula
          { text: "Neither.",outcomes: [11]}, // Outcome: You Escape the Palace
        ],
      },
    ],
  },
//path8  
  {
    "title": "The Seeker’s Revelation",
    "description": "Venture forth as a seeker of truth in this illuminating journey. You will explore the depths of knowledge and spirituality, encountering questions that challenge your perception of reality. Your choices will determine whether you achieve a transformative revelation or remain ensnared in the illusions of the world around you.",
    "questions": [
      {
        "text": "What do you seek most in life?",
        "options": [
          { "text": "Truth.", "next": 1 },
          { "text": "Fulfillment.", "next": 2 },
          { "text": "Power.", "next":3 },
          { "text": "Escape.",outcomes: [11]} // Outcome: You Escape the Palace
        ]
      },
      {
        "text": "What is the nature of truth?",
        "options": [
          { "text": "It is absolute.", "next": 4 },
          { "text": "It is relative.", "next": 5 },
          { "text": "It is unknowable.", "next": 2 },
          { "text": "It is dangerous.",outcomes: [14] } // Outcome: You are to live in the palace as Dracula's servant for eternity
        ]
      },
      {
        "text": "What would you sacrifice for fulfillment?",
        "options": [
          { "text": "Everything.", "next": 5 },
          { "text": "Nothing.", "next": 6 },
          { "text": "Some things.", "next": 3 },
          { "text": "I’m unsure.",outcomes: [11] } // Outcome: You Escape the Palace
        ]
      },
      {
        "text": "What is your relationship with power?",
        "options": [
          { "text": "I crave it.", "next": 4 },
          { "text": "I fear it.", "next": 7 },
          { "text": "I respect it.", "next": 5 },
          { "text": "It is a tool.",  outcomes: [13] } // Outcome: You become a Vampire serving Dracula
        ]
      },
      {
        "text": "What is your greatest fear?",
        "options": [
          { "text": "Failure.", "next": 5 },
          { "text": "Oblivion.", "next": 8 },
          { "text": "Betrayal.", "next": 6 },
          { "text": "I fear nothing.", outcomes: [12] } // Outcome: You are Dead
        ]
      },
      {
        "text": "Is it better to be feared or loved?",
        "options": [
          { "text": "Feared.", "next": 7 },
          { "text": "Loved.", "next": 8 },
          { "text": "Both.", "next": 6 },
          { "text": "Neither.", outcomes: [12]} // Outcome: You are Dead
        ]
      },
      {
        "text": "What is the value of loyalty?",
        "options": [
          { "text": "It is priceless.", "next": 8 },
          { "text": "It is earned.", "next": 9 },
          { "text": "It is a tool.",  outcomes: [13]}, // Outcome: You become a Vampire serving Dracula
          { "text": "It is overrated.", outcomes: [12] } // Outcome: You are Dead
        ]
      },
      {
        "text": "What would you sacrifice for power?",
        "options": [
          { "text": "Anything.", "next": 8 },
          { "text": "Nothing.", "next": 9 },
          { "text": "Some things.",outcomes: [14] }, // Outcome: You are to live in the palace as Dracula's servant for eternity
          { "text": "I don’t desire power.", outcomes: [11] } // Outcome: You Escape the Palace
        ]
      },
      {
        "text": "Can you ever truly escape fate?",
        "options": [
          { "text": "No, fate is unavoidable.", outcomes: [14] }, // Outcome: You are to live in the palace as Dracula's servant for eternity
          { "text": "Yes, we shape our own fate.", outcomes: [11] }, // Outcome: You Escape the Palace
          { "text": "I’m unsure.", outcomes: [12] }, // Outcome: You are Dead
          { "text": "Fate is irrelevant.",  outcomes: [13] } // Outcome: You become a Vampire serving Dracula
        ]
      },
      {
        "text": "Would you choose knowledge or power?",
        "options": [
          { "text": "Knowledge.",outcomes: [14]}, // Outcome: You are to live in the palace as Dracula's servant for eternity
          { "text": "Power.", outcomes: [13]}, // Outcome: You become a Vampire serving Dracula
          { "text": "Both.",  outcomes: [13] }, // Outcome: You become a Vampire serving Dracula
          { "text": "Neither.",outcomes: [11]} // Outcome: You Escape the Palace
        ]
      }
    ]
  },
//path9
  {
    "title": "The King’s Gambit",
    "description": "Step into a game of power, strategy, and intrigue. This path places you in the midst of courtly politics, where every decision carries weight. Confront questions about leadership, loyalty, and ambition as you navigate the treacherous waters of power dynamics. Your choices will dictate whether you rise as a ruler or fall victim to the games of those around you.",
    "questions": [
      {
        "text": "What is your opinion of leadership?",
        "options": [
          { "text": "It’s a burden.", "next": 1 },
          { "text": "It’s a privilege.", "next": 2 },
          { "text": "It’s a responsibility.", "next": 3 },
          { "text": "It’s irrelevant to me.",outcomes: [11] } // Outcome: You Escape the Palace
        ]
      },
      {
        "text": "What would you do to secure your position?",
        "options": [
          { "text": "Anything.", "next": 4 },
          { "text": "I would follow the rules.", "next": 5 },
          { "text": "I would fight for it.", "next": 2 },
          { "text": "I wouldn’t seek power.",outcomes: [14]} // Outcome: You are to live in the palace as Dracula's servant for eternity
        ]
      },
      {
        "text": "What is the most important trait in a leader?",
        "options": [
          { "text": "Strength.", "next": 4 },
          { "text": "Wisdom.", "next": 6 },
          { "text": "Compassion.", "next": 5 },
          { "text": "Cunning.", outcomes: [12] } // Outcome: You are Dead
        ]
      },
      {
        "text": "How do you view authority?",
        "options": [
          { "text": "It must be respected.", "next": 5 },
          { "text": "It must be challenged.", "next": 7 },
          { "text": "It’s necessary.", "next": 4 },
          { "text": "I don’t trust it.",outcomes: [11] } // Outcome: You Escape the Palace
        ]
      },
      {
        "text": "Would you betray someone to secure power?",
        "options": [
          { "text": "Yes.", "next": 6 },
          { "text": "No.", "next": 8 },
          { "text": "If necessary.", "next": 5 },
          { "text": "I would never betray.",outcomes: [12]} // Outcome: You are Dead
        ]
      },
      {
        "text": "What would you sacrifice for your kingdom?",
        "options": [
          { "text": "Myself.", "next": 7 },
          { "text": "Others.", "next": 8 },
          { "text": "Everything.", "next": 6 },
          { "text": "Nothing.", outcomes: [12]} // Outcome: You are Dead
        ]
      },
      {
        "text": "Is loyalty more important than victory?",
        "options": [
          { "text": "Yes.", "next": 8 },
          { "text": "No.", "next": 9 },
          { "text": "Both are important.", outcomes: [14]}, // Outcome: You are to live in the palace as Dracula's servant for eternity
          { "text": "Loyalty is overrated.",  outcomes: [13] } // Outcome: You become a Vampire serving Dracula
        ]
      },
      {
        "text": "Would you rather rule or serve?",
        "options": [
          { "text": "Rule.", "next": 8 },
          { "text": "Serve.", "next": 9 },
          { "text": "Neither.", outcomes: [11] }, // Outcome: You Escape the Palace
          { "text": "I don’t care.", outcomes: [12]} // Outcome: You are Dead
        ]
      },
      {
        "text": "Is the crown worth the weight?",
        "options": [
          { "text": "Yes.",  outcomes: [13] }, // Outcome: You become a Vampire serving Dracula
          { "text": "No.", outcomes: [14] }, // Outcome: You are to live in the palace as Dracula's servant for eternity
          { "text": "I’m not sure.",outcomes: [11] }, // Outcome: You Escape the Palace
          { "text": "It is a burden.", outcomes: [12] } // Outcome: You are Dead
        ]
      },
      {
        "text": "Would you betray your kingdom for immortality?",
        "options": [
          { "text": "Yes.",  outcomes: [13] }, // Outcome: You become a Vampire serving Dracula
          { "text": "No.",outcomes: [14] }, // Outcome: You are to live in the palace as Dracula's servant for eternity
          { "text": "Only if necessary.",  outcomes: [13] }, // Outcome: You become a Vampire serving Dracula
          { "text": "I would never betray.", outcomes: [12] } // Outcome: You are Dead
        ]
      }
    ]
  },
  //path-10
  {
    "title": "The Survivor’s Gamble",
    "description": "Face the ultimate test of resilience and survival in this gripping journey. Encounter questions that challenge your instincts, ethics, and will to survive against overwhelming odds. Each decision will shape your fate, determining whether you emerge as a triumphant survivor or succumb to the harsh realities of the world you inhabit.",
    "questions": [
      {
        "text": "What is the key to survival?",
        "options": [
          { "text": "Adaptation", "next": 1 },
          { "text": "Strength", "next": 2 },
          { "text": "Cunning", "next": 3 },
          { "text": "Luck", outcomes: [11] } // You escape the palace
        ]
      },
      {
        "text": "Would you sacrifice your morals for survival?",
        "options": [
          { "text": "Yes", "next": 4 },
          { "text": "No", "next": 5 },
          { "text": "It depends", "next": 2 },
          { "text": "I don't know", outcomes: [14] } // You live as Dracula's servant for eternity
        ]
      },
      {
        "text": "Is it better to be smart or strong?",
        "options": [
          { "text": "Smart", "next": 4 },
          { "text": "Strong", "next": 6 },
          { "text": "Both", "next": 5 },
          { "text": "Neither", outcomes: [11] } // You escape the palace
        ]
      },
      {
        "text": "What would you do to survive?",
        "options": [
          { "text": "Anything", "next": 5 },
          { "text": "Fight", "next": 7 },
          { "text": "Run", "next": 4 },
          { "text": "Hide", outcomes: [12] } // You are dead
        ]
      },
      {
        "text": "Is survival more important than happiness?",
        "options": [
          { "text": "Yes", "next": 5 },
          { "text": "No", "next": 8 },
          { "text": "It depends", "next": 6 },
          { "text": "I don't know", outcomes: [11] } // You escape the palace
        ]
      },
      {
        "text": "Can you sacrifice others for your own survival?",
        "options": [
          { "text": "Yes", "next": 7 },
          { "text": "No", "next": 8 },
          { "text": "Only if necessary", "next": 6 },
          { "text": "Never",outcomes: [12] } // You are dead
        ]
      },
      {
        "text": "Is loyalty a weakness in survival?",
        "options": [
          { "text": "Yes", "next": 8 },
          { "text": "No", "next": 9 },
          { "text": "It depends",  outcomes: [13] }, // You become a vampire serving Dracula
          { "text": "Loyality is strength", outcomes: [12]} //you are dead
        ]
      },
      {
        "text": "Would you rather live forever or live well?",
        "options": [
          { "text": "Live forever", "next": 8 },
          { "text": "Live well", "next": 9 },
          { "text": "Both",  outcomes: [13] }, // You become a vampire serving Dracula
          { "text": "Neither",outcomes: [11]} // you Escape the place
        ]
      },
      {
        "text": "What is the price of immortality?",
        "options": [
          { "text": "My soul",  outcomes: [13]}, // You become a vampire serving Dracula
          { "text": "My freedom", outcomes: [14] }, // You live as Dracula's servant for eternity
          { "text": "I don't know", outcomes: [12] }, // You are dead
          { "text": "There is no price",outcomes: ["You Escape the Palace"] } // You escape the palace
        ]
      },
      {
        "text": "Would you betray your humanity for survival?",
        "options": [
          { "text": "Yes",  outcomes: [13] }, // You become a vampire serving Dracula
          { "text": "No", outcomes: [14] }, // You live as Dracula's servant for eternity
          { "text": "I don't know",outcomes: [11] }, // You escape the palace
          { "text": "I would never", outcomes: [12]} // You are dead
        ]
      }
    ]
  },
//path11
  {
    "title": "The Sage’s Reflection",
    "description": "Venture into the depths of wisdom and introspection with the Sage. Explore profound questions about life, morality, and the nature of existence. What truths will you uncover in your quest for enlightenment?.",
    "questions": [
      {
        "text": "What is wisdom?",
        "options": [
          { "text": "Experience.", "next": 1 },
          { "text": "Knowledge.", "next": 2 },
          { "text": "Intuition.", "next": 3 },
          { "text": "It doesn’t matter.",outcomes: [11]} // Outcome: You Escape the Palace
        ]
      },
      {
        "text": "Can wisdom be learned, or is it inherent?",
        "options": [
          { "text": "It can be learned.", "next": 4 },
          { "text": "It is inherent.", "next": 5 },
          { "text": "A combination of both.", "next": 2 },
          { "text": "It cannot be learned.", outcomes: [12] } // Outcome: You are Dead
        ]
      },
      {
        "text": "Is wisdom worth more than power?",
        "options": [
          { "text": "Yes.", "next": 4 },
          { "text": "No.", "next": 6 },
          { "text": "They are equal.", "next": 3 },
          { "text": "Neither matters.", outcomes: [14] } // Outcome: You are to live in the palace as Dracula's servant for eternity
        ]
      },
      {
        "text": "Does wisdom come with age?",
        "options": [
          { "text": "Always.", "next": 5 },
          { "text": "Not necessarily.", "next": 7 },
          { "text": "Sometimes.", "next": 4 },
          { "text": "Never.",outcomes: [11]} // Outcome: You Escape the Palace
        ]
      },
      {
        "text": "Can one be wise and selfish?",
        "options": [
          { "text": "Yes.", "next": 5 },
          { "text": "No.", "next": 8 },
          { "text": "It depends.", "next": 6 },
          { "text": "I’m not sure.",outcomes: [12] } // Outcome: You are Dead
        ]
      },
      {
        "text": "What would you do with great wisdom?",
        "options": [
          { "text": "Help others.", "next": 7 },
          { "text": "Gain power.", "next": 8 },
          { "text": "Seek immortality.",  outcomes: [13] }, // Outcome: You become a Vampire serving Dracula
          { "text": "I don’t know.", outcomes: [11]} // Outcome: You Escape the Palace
        ]
      },
      {
        "text": "Can wisdom be dangerous?",
        "options": [
          { "text": "Yes.", "next": 8 },
          { "text": "No.", "next": 9 },
          { "text": "Only in the wrong hands.",outcomes: [14] }, // Outcome: You are to live in the palace as Dracula's servant for eternity
          { "text": "Wisdom is always good.", outcomes: [12]} // Outcome: You are Dead
        ]
      },
      {
        "text": "Does wisdom lead to loneliness?",
        "options": [
          { "text": "Yes.", "next": 8 },
          { "text": "No.", "next": 9 },
          { "text": "Sometimes.",outcomes: [14] }, // Outcome: You are to live in the palace as Dracula's servant for eternity
          { "text": "I’m unsure.", outcomes: [12] } // Outcome: You are Dead
        ]
      },
      {
        "text": "What is the price of wisdom?",
        "options": [
          { "text": "Suffering.",  outcomes: [13] }, // Outcome: You become a Vampire serving Dracula
          { "text": "Loneliness.",outcomes: [11]} ,// Outcome: You Escape the Palace
          { "text": "There is no price.", outcomes: [12] } ,// Outcome: You are Dead
          { "text": "I don’t know.",outcomes: [14] } // Outcome: You are to live in the palace as Dracula's servant for eternity
        ]
      },
      {
        "text": "Can wisdom save you from death?",
        "options": [
          { "text": "Yes.",outcomes: [11] }, // Outcome: You Escape the Palace
          { "text": "No.",outcomes: [14] } ,// Outcome: You are to live in the palace as Dracula's servant for eternity
          { "text": "Only if I become a vampire.", outcomes: [13] } ,// Outcome: You become a Vampire serving Dracula
          { "text": "Death is inevitable.", outcomes: [12]} // Outcome: You are Dead
        ]
      }
    ]
  },
//path12
  {
    "title": "The Merchant’s Bargain",
    "description": "Step into the bustling marketplace of the mind, where deals are struck and desires are fulfilled. Will you negotiate for wealth, wisdom, or power? The choices you make will determine your fate!y.",
    "questions": [
      {
        "text": "What is the most valuable thing in life?",
        "options": [
          { "text": "Wealth.", "next": 1 },
          { "text": "Health.", "next": 2 },
          { "text": "Love.", "next": 3 },
          { "text": "Freedom.", outcomes: [11] } // Outcome: You Escape the Palace
        ]
      },
      {
        "text": "Would you sacrifice wealth for power?",
        "options": [
          { "text": "Yes.", "next": 4 },
          { "text": "No.", "next": 5 },
          { "text": "Only if necessary.", "next": 2 },
          { "text": "I don’t know.",outcomes: [12] } // Outcome: You are Dead
        ]
      },
      {
        "text": "Can wealth buy happiness?",
        "options": [
          { "text": "Yes.", "next": 4 },
          { "text": "No.", "next": 6 },
          { "text": "It can help.", "next": 3 },
          { "text": "It depends.", outcomes: [14]} // Outcome: You are to live in the palace as Dracula's servant for eternity
        ]
      },
      {
        "text": "What would you give for eternal life?",
        "options": [
          { "text": "Everything.", "next": 5 },
          { "text": "Nothing.", "next": 7 },
          { "text": "I’m not sure.", "next": 4 },
          { "text": "It depends on the cost.",outcomes: [11] } // Outcome: You Escape the Palace
        ]
      },
      {
        "text": "What is worth more: love or gold?",
        "options": [
          { "text": "Love.", "next": 5 },
          { "text": "Gold.", "next": 8 },
          { "text": "Both are valuable.", "next": 6 },
          { "text": "Neither matters.", outcomes: [12] } // Outcome: You are Dead
        ]
      },
      {
        "text": "Would you betray a friend for wealth?",
        "options": [
          { "text": "Yes.", "next": 7 },
          { "text": "No.", "next": 8 },
          { "text": "It depends.", "next": 6 },
          { "text": "I would never.", outcomes: [12] } // Outcome: You are Dead
        ]
      },
      {
        "text": "What would you sacrifice for a fortune?",
        "options": [
          { "text": "My freedom.", "next": 8 },
          { "text": "My soul.", "next": 9 },
          { "text": "Nothing.",outcomes: [11] }, // Outcome: You Escape the Palace
          { "text": "I don’t know.", outcomes: [12]} // Outcome: You are Dead
        ]
      },
      {
        "text": "Is power more valuable than wealth?",
        "options": [
          { "text": "Yes.", "next": 8 },
          { "text": "No.", "next": 9 },
          { "text": "They are equal.", outcomes: [13] }, // Outcome: You become a Vampire serving Dracula
          { "text": "I’m not sure.",outcomes: [11] } // Outcome: You Escape the Palace
        ]
      },
      {
        "text": "Would you trade your humanity for riches?",
        "options": [
          { "text": "Yes.",  outcomes: [13]}, // Outcome: You become a Vampire serving Dracula
          { "text": "No.",outcomes: [14]}, // Outcome: You are to live in the palace as Dracula's servant for eternity
          { "text": "It depends.", outcomes: [11]} ,// Outcome: You Escape the Palace
          { "text": "I would never betray my humanity.", outcomes: [12] } // Outcome: You are Dead
        ]
      },
      {
        "text": "Is everything for sale?",
        "options": [
          { "text": "Yes.",  outcomes: ["You become a Vampire serving Dracula"] }, // Outcome: You become a Vampire serving Dracula
          { "text": "No.", outcomes: [12] }, // Outcome: You are Dead
          { "text": "Only some things.",outcomes: [11]  } ,// Outcome: You Escape the Palace
          { "text": "I’m not sure.", outcomes: [14]  } // Outcome: You are to live in the palace as Dracula's servant for eternity
        ]
      }
    ]
  },
//path13
  {
  "title": "The Artist’s Muse",
  "description": "Embark on a captivating journey as a passionate artist seeking inspiration. Encounter questions that delve into the depths of beauty, tragedy, and the transformative power of art. Your responses will lead you to discover whether you become a timeless muse or fall victim to the darker sides of creativity.",
  "questions": [
    {
      "text": "What is true beauty?",
      "options": [
        { "text": "In the eye of the beholder.", "next": 1 },
        { "text": "Nature.", "next": 2 },
        { "text": "Art.", "next": 3 },
        { "text": "It doesn’t exist.",outcomes: [11] } // Outcome: You Escape the Palace
      ]
    },
    {
      "text": "Can beauty be found in darkness?",
      "options": [
        { "text": "Yes.", "next": 4 },
        { "text": "No.", "next": 5 },
        { "text": "Only in art.", "next": 2 },
        { "text": "It depends.",outcomes: [12] } // Outcome: You are Dead
      ]
    },
    {
      "text": "What is the purpose of art?",
      "options": [
        { "text": "To express emotions.", "next": 4 },
        { "text": "To tell stories.", "next": 6 },
        { "text": "To provoke thought.", "next": 3 },
        { "text": "It has no purpose.",outcomes: [11]} // Outcome: You Escape the Palace
      ]
    },
    {
      "text": "Can art heal?",
      "options": [
        { "text": "Yes.", "next": 5 },
        { "text": "No.", "next": 7 },
        { "text": "Sometimes.", "next": 4 },
        { "text": "I don’t know.",outcomes: [12] } // Outcome: You are Dead
      ]
    },
    {
      "text": "What would you sacrifice for your art?",
      "options": [
        { "text": "Everything.", "next": 5 },
        { "text": "Nothing.", "next": 8 },
        { "text": "Time.", "next": 6 },
        { "text": "I’m not sure.", outcomes: [12] } // Outcome: You are Dead
      ]
    },
    {
      "text": "Is passion more important than skill?",
      "options": [
        { "text": "Yes.", "next": 7 },
        { "text": "No.", "next": 8 },
        { "text": "They are equal.",  outcomes: [13] } ,// Outcome: You become a Vampire serving Dracula
        { "text": "It depends.", outcomes: [11]} // Outcome: You Escape the Palace
      ]
    },
    {
      "text": "Can art be dangerous?",
      "options": [
        { "text": "Yes.", "next": 8 },
        { "text": "No.", "next": 9 },
        { "text": "Only if misunderstood.",  outcomes: [13] } ,// Outcome: You become a Vampire serving Dracula
        { "text": "Art is always good.", outcomes: [11] } // Outcome: You Escape the Palace
      ]
    },
    {
      "text": "What would you do for your muse?",
      "options": [
        { "text": "Anything.", "next": 9 },
        { "text": "Nothing.", outcomes: [12] } ,// Outcome: You are Dead
        { "text": "I don’t know.",outcomes: [11] }, // Outcome: You Escape the Palace
        { "text": "I would fight for them.",outcomes: [14] } // Outcome: You are to live in the palace as Dracula's servant for eternity
      ]
    },
    {
      "text": "Is there beauty in tragedy?",
      "options": [
        { "text": "Yes.",  outcomes: [13] } ,// Outcome: You become a Vampire serving Dracula
        { "text": "No.",outcomes: [11] } ,// Outcome: You Escape the Palace
        { "text": "Sometimes.", outcomes: [12] }, // Outcome: You are Dead
        { "text": "Beauty can come from tragedy.",outcomes: [14] } // Outcome: You are to live in the palace as Dracula's servant for eternity
      ]
    },
    {
      "text": "Is the pursuit of beauty worth the risk?",
      "options": [
        { "text": "Yes.",  outcomes: [13]}, // Outcome: You become a Vampire serving Dracula
        { "text": "No.", outcomes: [14] }, // Outcome: You are to live in the palace as Dracula's servant for eternity
        { "text": "It depends.",outcomes: [11] } ,// Outcome: You Escape the Palace
        { "text": "I would risk everything.", outcomes: [12] } // Outcome: You are Dead
      ]
    }
  ]
  },
//path14
  {
    "title": "The Healer’s Path",
    "description": "Enter the realm of healing, where compassion and sacrifice intertwine. This path will challenge your understanding of health and wellness, pushing you to consider the limits of your altruism. Your choices will reflect your true values, ultimately revealing whether you emerge as a savior or a lost soul.",
    "questions": [
      {
        "text": "What is the greatest form of healing?",
        "options": [
          { "text": "Medicine.", "next": 1 },
          { "text": "Love.", "next": 2 },
          { "text": "Time.", "next": 3 },
          { "text": "Nature.", outcomes: [11] } // Outcome: You Escape the Palace
        ]
      },
      {
        "text": "Can one heal without compassion?",
        "options": [
          { "text": "Yes.", "next": 4 },
          { "text": "No.", "next": 5 },
          { "text": "It depends on the situation.", "next": 2 },
          { "text": "Compassion is essential.", outcomes: [12] } // Outcome: You are Dead
        ]
      },
      {
        "text": "Is physical health more important than mental health?",
        "options": [
          { "text": "Yes.", "next": 4 },
          { "text": "No.", "next": 6 },
          { "text": "They are equal.", "next": 3 },
          { "text": "I’m not sure.", outcomes: [11] } // Outcome: You Escape the Palace
        ]
      },
      {
        "text": "What is the role of pain in healing?",
        "options": [
          { "text": "It is necessary.", "next": 5 },
          { "text": "It is a hindrance.", "next": 7 },
          { "text": "It teaches us.", "next": 4 },
          { "text": "Pain has no role.", outcomes: [12] } // Outcome: You are Dead
        ]
      },
      {
        "text": "Can healing come from suffering?",
        "options": [
          { "text": "Yes.", "next": 5 },
          { "text": "No.", "next": 8 },
          { "text": "Sometimes.", "next": 6 },
          { "text": "It depends.", outcomes: [11] } // Outcome: You Escape the Palace
        ]
      },
      {
        "text": "What would you sacrifice for someone’s health?",
        "options": [
          { "text": "My own health.", "next": 7 },
          { "text": "Nothing.", "next": 8 },
          { "text": "Time.", "next": 6 },
          { "text": "I don’t know.",outcomes: [12]} // Outcome: You are Dead
        ]
      },
      {
        "text": "Is it better to heal physically or emotionally?",
        "options": [
          { "text": "Physically.", "next": 8 },
          { "text": "Emotionally.", "next": 9 },
          { "text": "Both are vital.",  outcomes: [13] } ,// Outcome: You become a Vampire serving Dracula
          { "text": "Neither matters.",outcomes: [11]  } // Outcome: You Escape the Palace
        ]
      },
      {
        "text": "Would you risk your life to save another?",
        "options": [
          { "text": "Yes.", "next": 9 },
          { "text": "No.", outcomes: [12] }, // Outcome: You are Dead
          { "text": "Only for a loved one.",outcomes: [14]  }, // Outcome: You are to live in the palace as Dracula's servant for eternity
          { "text": "I’m not sure.", outcomes: [11] } // Outcome: You Escape the Palace
        ]
      },
      {
        "text": "What is the most important quality in a healer?",
        "options": [
          { "text": "Compassion.", outcomes: [11] }, // Outcome: You Escape the Palace
          { "text": "Skill.",outcomes: [12] } ,// Outcome: You are Dead
          { "text": "Knowledge.",outcomes: [14]  } ,// Outcome: You are to live in the palace as Dracula's servant for eternity
          { "text": "Experience.",  outcomes: [13]} // Outcome: You become a Vampire serving Dracula
        ]
      },
      {
        "text": "Can healing be a curse?",
        "options": [
          { "text": "Yes.",  outcomes: [13] }, // Outcome: You become a Vampire serving Dracula
          { "text": "No.",outcomes: [11]  }, // Outcome: You Escape the Palace
          { "text": "It can be.", outcomes: [12]}, // Outcome: You are Dead
          { "text": "Healing is always a gift.", outcomes: [14]  } // Outcome: You are to live in the palace as Dracula's servant for eternity
        ]
      }
    ]
  },
//path115
  {
    "title": "The Traveler’s Tale",
    "description": "Set forth on an adventurous journey, where the spirit of exploration ignites your desire for discovery. Encounter thought-provoking questions about the essence of travel, risk, and personal growth. Your answers will shape your fate, guiding you to either return home enriched or wander forever in the unknown.",
    "questions": [
      {
        "text": "What is the essence of travel?",
        "options": [
          { "text": "Discovery.", "next": 1 },
          { "text": "Escape.", "next": 2 },
          { "text": "Adventure.", "next": 3 },
          { "text": "Knowledge.", outcomes: [11]  } // Outcome: You Escape the Palace
        ]
      },
      {
        "text": "Is travel worth the risks?",
        "options": [
          { "text": "Yes.", "next": 4 },
          { "text": "No.", "next": 5 },
          { "text": "It depends on the destination.", "next": 2 },
          { "text": "I’m not sure.", outcomes: [12] } // Outcome: You are Dead
        ]
      },
      {
        "text": "What is the most important lesson learned from travel?",
        "options": [
          { "text": "Adaptability.", "next": 4 },
          { "text": "Courage.", "next": 6 },
          { "text": "Patience.", "next": 3 },
          { "text": "Travel teaches nothing.", outcomes: [12] } // Outcome: You are Dead
        ]
      },
      {
        "text": "Can travel change a person?",
        "options": [
          { "text": "Yes.", "next": 5 },
          { "text": "No.", "next": 7 },
          { "text": "It can open one’s eyes.", "next": 4 },
          { "text": "I don’t know.", outcomes: [11]  } // Outcome: You Escape the Palace
        ]
      },
      {
        "text": "Would you give up everything for the chance to travel?",
        "options": [
          { "text": "Yes.", "next": 5 },
          { "text": "No.", "next": 8 },
          { "text": "Only if it was a great opportunity.", "next": 6 },
          { "text": "I’m not sure.", outcomes: [12]  } // Outcome: You are Dead
        ]
      },
      {
        "text": "What is more valuable: a passport or a ticket?",
        "options": [
          { "text": "Passport.", "next": 7 },
          { "text": "Ticket.", "next": 8 },
          { "text": "Both are essential.", outcomes: [12] }, // Outcome: You are Dead
          { "text": "Neither matters.",outcomes: [11]  } // Outcome: You Escape the Palace
        ]
      },
      {
        "text": "What do you fear the most while traveling?",
        "options": [
          { "text": "Getting lost.", "next": 8 },
          { "text": "Meeting dangerous people.", "next": 9 },
          { "text": "Failure.",  outcomes: [13]}, // Outcome: You become a Vampire serving Dracula
          { "text": "I fear nothing.",outcomes: [11] } // Outcome: You Escape the Palace
        ]
      },
      {
        "text": "What is the most essential item to pack?",
        "options": [
          { "text": "Water.", "next": 9 },
          { "text": "Clothing.", outcomes: [11]  } ,// Outcome: You Escape the Palace
          { "text": "A map.", outcomes: [12] }, // Outcome: You are Dead
          { "text": "A journal.",  outcomes: [13] } // Outcome: You become a Vampire serving Dracula
        ]
      },
      {
        "text": "Can one travel without a destination?",
        "options": [
          { "text": "Yes.",  outcomes: [13] }, // Outcome: You become a Vampire serving Dracula
          { "text": "No.", outcomes: [12] }, // Outcome: You are Dead
          { "text": "It’s about the journey.", outcomes: [11]  }, // Outcome: You Escape the Palace
          { "text": "I’m not sure.", outcomes: [14]  } // Outcome: You are to live in the palace as Dracula's servant for eternity
        ]
      },
      {
        "text": "Is the world a safe place to explore?",
        "options": [
          { "text": "Yes.", outcomes: [11] }, // Outcome: You Escape the Palace
          { "text": "No.", outcomes: [12] } ,// Outcome: You are Dead
          { "text": "Only if you're cautious.",  outcomes: [13]} ,// Outcome: You become a Vampire serving Dracula
          { "text": "It depends on where you go.", outcomes: [14]  } // Outcome: You are to live in the palace as Dracula's servant for eternity
        ]
      }
    ]
  },
//path16
  {
    "title": "The Dreamer’s Escape",
    "description": "Dive into the surreal realm of dreams and imagination. With the Dreamer as your guide, explore the boundaries of reality and fantasy. What truths lie hidden in the depths of your subconscious?.",
    "questions": [
      {
        "text": "What is your greatest dream?",
        "options": [
          { "text": "Freedom.", "next": 1 },
          { "text": "Adventure.", "next": 2 },
          { "text": "Peace.", "next": 3 },
          { "text": "Escape.",outcomes: [11]} // Outcome: You Escape the Palace
        ]
      },
      {
        "text": "Would you give up everything for your dream?",
        "options": [
          { "text": "Yes.", "next": 4 },
          { "text": "No.", "next": 5 },
          { "text": "Depends on the cost.", "next": 2 },
          { "text": "I’m not sure.", outcomes: [14]} // Outcome: You are to live in the palace as Dracula's servant for eternity
        ]
      },
      {
        "text": "What is worth more—safety or freedom?",
        "options": [
          { "text": "Freedom.", "next": 4 },
          { "text": "Safety.", "next": 6 },
          { "text": "Both.", "next": 5 },
          { "text": "Neither.",outcomes: [11]  } // Outcome: You Escape the Palace
        ]
      },
      {
        "text": "What do dreams mean to you?",
        "options": [
          { "text": "They are everything.", "next": 5 },
          { "text": "They are illusions.", "next": 7 },
          { "text": "They are guidance.", "next": 4 },
          { "text": "They are dangerous.", outcomes: [12] } // Outcome: You are Dead
        ]
      },
      {
        "text": "What would you sacrifice for your dream?",
        "options": [
          { "text": "Everything.", "next": 5 },
          { "text": "Nothing.", "next": 8 },
          { "text": "It depends.", "next": 6 },
          { "text": "I don’t know.",outcomes: [11] } // Outcome: You Escape the Palace
        ]
      },
      {
        "text": "Can you live without your dream?",
        "options": [
          { "text": "No.", "next": 7 },
          { "text": "Yes.", "next": 8 },
          { "text": "Maybe.", "next": 6 },
          { "text": "I don’t know.", outcomes: [12] } // Outcome: You are Dead
        ]
      },
      {
        "text": "Is a dream worth dying for?",
        "options": [
          { "text": "Yes.", "next": 8 },
          { "text": "No.", "next": 9 },
          { "text": "Only if it’s achieved.", outcomes: [14]  } ,// Outcome: You are to live in the palace as Dracula's servant for eternity
          { "text": "Dreams change.", outcomes: [11]  } // Outcome: You Escape the Palace
        ]
      },
      {
        "text": "What do nightmares teach us?",
        "options": [
          { "text": "They are warnings.", "next": 8 },
          { "text": "They are reflections of fear.", "next": 9 },
          { "text": "They are nothing.",outcomes: [12] }, // Outcome: You are Dead
          { "text": "They reveal our weaknesses.",  outcomes: [13]} // Outcome: You become a Vampire serving Dracula
        ]
      },
      {
        "text": "Would you abandon your dream for power?",
        "options": [
          { "text": "No.",outcomes: [11] }, // Outcome: You Escape the Palace
          { "text": "Yes.",  outcomes: [13] }, // Outcome: You become a Vampire serving Dracula
          { "text": "Maybe.", outcomes: [14] }, // Outcome: You are to live in the palace as Dracula's servant for eternity
          { "text": "I don’t know.",outcomes: [12] } // Outcome: You are Dead
        ]
      },
      {
        "text": "Can dreams survive eternity?",
        "options": [
          { "text": "Yes.",  outcomes: [13] }, // Outcome: You become a Vampire serving Dracula
          { "text": "No.",outcomes: [14]  }, // Outcome: You are to live in the palace as Dracula's servant for eternity
          { "text": "Only if you change.",outcomes: [11] } ,// Outcome: You Escape the Palace
          { "text": "I’m not sure.", outcomes: [12] } // Outcome: You are Dead
        ]
      }
    ]
  },
//path17
  {
    "title": "The Adventurer’s Crossroads",
    "description": "Face the ultimate test of courage and cunning at the Adventurer's Crossroads. Choose your path wisely as you encounter challenges, treasures, and foes. Will you emerge victorious or face dire consequences?ery.",
    "questions": [
      {
        "text": "What is the essence of adventure?",
        "options": [
          { "text": "Discovery.", "next": 1 },
          { "text": "Danger.", "next": 2 },
          { "text": "Freedom.", "next": 3 },
          { "text": "Escape.", outcomes: [11]  } // Outcome: You Escape the Palace
        ]
      },
      {
        "text": "Do you prefer the known or the unknown?",
        "options": [
          { "text": "The unknown.", "next": 4 },
          { "text": "The known.", "next": 5 },
          { "text": "Both.", "next": 2 },
          { "text": "Neither.", outcomes: [14]  } // Outcome: You are to live in the palace as Dracula's servant for eternity
        ]
      },
      {
        "text": "What would you face for adventure?",
        "options": [
          { "text": "Death.", "next": 4 },
          { "text": "Fear.", "next": 6 },
          { "text": "Loneliness.", "next": 5 },
          { "text": "Nothing.", outcomes: [11]} // Outcome: You Escape the Palace
        ]
      },
      {
        "text": "Can freedom be found in danger?",
        "options": [
          { "text": "Yes.", "next": 5 },
          { "text": "No.", "next": 7 },
          { "text": "Sometimes.", "next": 4 },
          { "text": "I don’t know.", outcomes: [12] } // Outcome: You are Dead
        ]
      },
      {
        "text": "What would you risk to discover the unknown?",
        "options": [
          { "text": "Everything.", "next": 5 },
          { "text": "Nothing.", "next": 8 },
          { "text": "It depends.", "next": 6 },
          { "text": "I’m not sure.",outcomes: [11]  } // Outcome: You Escape the Palace
        ]
      },
      {
        "text": "Is fear a barrier or a challenge?",
        "options": [
          { "text": "A challenge.", "next": 7 },
          { "text": "A barrier.", "next": 8 },
          { "text": "Both.", "next": 6 },
          { "text": "Neither.", outcomes: [12]} // Outcome: You are Dead
        ]
      },
      {
        "text": "Is adventure worth suffering for?",
        "options": [
          { "text": "Yes.", "next": 8 },
          { "text": "No.", "next": 9 },
          { "text": "Only if it’s meaningful.",outcomes: [14]  } ,// Outcome: You are to live in the palace as Dracula's servant for eternity
          { "text": "Adventure is fleeting.", outcomes: [11]  } // Outcome: You Escape the Palace
        ]
      },
      {
        "text": "What is the price of freedom?",
        "options": [
          { "text": "Pain.", "next": 8 },
          { "text": "Isolation.", "next": 9 },
          { "text": "Nothing.", outcomes: [12] }, // Outcome: You are Dead
          { "text": "Your soul.",  outcomes: [13] } // Outcome: You become a Vampire serving Dracula
        ]
      },
      {
        "text": "Would you trade adventure for immortality?",
        "options": [
          { "text": "No.", outcomes: [11]  }, // Outcome: You Escape the Palace
          { "text": "Yes.", outcomes: [13] } ,// Outcome: You become a Vampire serving Dracula
          { "text": "Only if forced.", outcomes: [14]  }, // Outcome: You are to live in the palace as Dracula's servant for eternity
          { "text": "I’m not sure.", outcomes: [12] } // Outcome: You are Dead
        ]
      },
      {
        "text": "Does adventure end in death or glory?",
        "options": [
          { "text": "Death.", outcomes: [12] }, // Outcome: You are Dead
          { "text": "Glory.",  outcomes: [13] }, // Outcome: You become a Vampire serving Dracula
          { "text": "Both.",outcomes: [11]  }, // Outcome: You Escape the Palace
          { "text": "Neither.", outcomes: [14]  } // Outcome: You are to live in the palace as Dracula's servant for eternity
        ]
      }
    ]
  },
//path18
  {
    "title": "The Rogue’s Risk",
    "description": "Step into the shadowy world of the Rogue, where cunning and deception reign. Make daring choices and test your luck as you navigate treacherous alliances. How far are you willing to go for freedom?.",
    "questions": [
      {
        "text": "What do you value most?",
        "options": [
          { "text": "Freedom.", "next": 1 },
          { "text": "Wealth.", "next": 2 },
          { "text": "Power.", "next": 3 },
          { "text": "Nothing.", outcomes: [11]  } // Outcome: You Escape the Palace
        ]
      },
      {
        "text": "Would you betray someone for freedom?",
        "options": [
          { "text": "Yes.", "next": 4 },
          { "text": "No.", "next": 5 },
          { "text": "Only if necessary.", "next": 2 },
          { "text": "I would never.",outcomes: [14]  } // Outcome: You are to live in the palace as Dracula's servant for eternity
        ]
      },
      {
        "text": "How far would you go for wealth?",
        "options": [
          { "text": "To any length.", "next": 4 },
          { "text": "I have limits.", "next": 6 },
          { "text": "I only need enough.", "next": 5 },
          { "text": "Wealth is meaningless to me.", outcomes: [11] } // Outcome: You Escape the Palace
        ]
      },
      {
        "text": "Do you believe power corrupts?",
        "options": [
          { "text": "Yes.", "next": 5 },
          { "text": "No.", "next": 7 },
          { "text": "Only the weak are corrupted.", "next": 4 },
          { "text": "I don’t care.",  outcomes: [13] } // Outcome: You become a Vampire serving Dracula
        ]
      },
      {
        "text": "Would you sacrifice morality for gain?",
        "options": [
          { "text": "Yes.", "next": 6 },
          { "text": "No.", "next": 8 },
          { "text": "If necessary.", "next": 5 },
          { "text": "I hold my morals.", outcomes: [12]} // Outcome: You are Dead
        ]
      },
      {
        "text": "What is the price of freedom?",
        "options": [
          { "text": "Sacrifice.", "next": 7 },
          { "text": "Loyalty.", "next": 8 },
          { "text": "I don’t know.", "next": 6 },
          { "text": "Freedom has no price.",outcomes: [11]  } // Outcome: You Escape the Palace
        ]
      },
      {
        "text": "Do you trust others easily?",
        "options": [
          { "text": "Yes.", "next": 8 },
          { "text": "No.", "next": 9 },
          { "text": "I’m cautious.",outcomes: [12] }, // Outcome: You are Dead
          { "text": "I trust no one.",  outcomes: [13]} // Outcome: You become a Vampire serving Dracula
        ]
      },
      {
        "text": "Is it better to steal than beg?",
        "options": [
          { "text": "Yes.", "next": 8 },
          { "text": "No.", "next": 9 },
          { "text": "Only in desperate times.", outcomes: [14]  }, // Outcome: You are to live in the palace as Dracula's servant for eternity
          { "text": "Stealing is wrong.", outcomes: [12] } // Outcome: You are Dead
        ]
      },
      {
        "text": "Would you live a lie for survival?",
        "options": [
          { "text": "Yes.", outcomes: [13] }, // Outcome: You become a Vampire serving Dracula
          { "text": "No.",outcomes: [14]  }, // Outcome: You are to live in the palace as Dracula's servant for eternity
          { "text": "Depends on the situation.",outcomes: [11] , }, // Outcome: You Escape the Palace
          { "text": "I’d rather die.",outcomes: [12] } // Outcome: You are Dead
        ]
      },
      {
        "text": "What would you risk to win everything?",
        "options": [
          { "text": "Everything.",  outcomes: [13]}, // Outcome: You become a Vampire serving Dracula
          { "text": "Only what I can afford to lose.",outcomes: [11]  } ,// Outcome: You Escape the Palace
          { "text": "Nothing.", outcomes: [14] }, // Outcome: You are to live in the palace as Dracula's servant for eternity
          { "text": "I wouldn’t risk anything.", outcomes: [12] } // Outcome: You are Dead
        ]
      }
    ]
  },
//path19
  {
    "title": "The Alchemist’s Pursuit",
    "description": "Unlock the secrets of transformation with the Alchemist. Experiment with ideas of creation and change, as you seek the elusive Philosopher’s Stone. What transmutations will your choices ignite?.",
    "questions": [
      {
        "text": "Can you transmute lead into gold?",
        "options": [
          { "text": "Yes.", "next": 1 },
          { "text": "No.", "next": 2 },
          { "text": "With the right knowledge.", "next": 3 },
          { "text": "Only in theory.",outcomes: [11] } // Outcome: You Escape the Palace
        ]
      },
      {
        "text": "What is the most valuable element?",
        "options": [
          { "text": "Gold.", "next": 4 },
          { "text": "Fire.", "next": 5 },
          { "text": "Time.", "next": 2 },
          { "text": "Wisdom.",outcomes: [12] } // Outcome: You are Dead
        ]
      },
      {
        "text": "Can you create life from nothing?",
        "options": [
          { "text": "Yes.", "next": 4 },
          { "text": "No.", "next": 6 },
          { "text": "Not alone.", "next": 5 },
          { "text": "Life comes from life.", outcomes: [11] } // Outcome: You Escape the Palace
        ]
      },
      {
        "text": "Is alchemy the key to immortality?",
        "options": [
          { "text": "Yes.", "next": 5 },
          { "text": "No.", "next": 7 },
          { "text": "It holds part of the secret.", "next": 4 },
          { "text": "Immortality is a myth.", outcomes: [12] } // Outcome: You are Dead
        ]
      },
      {
        "text": "Can knowledge be transmuted into power?",
        "options": [
          { "text": "Yes.", "next": 5 },
          { "text": "No.", "next": 8 },
          { "text": "Only if wielded wisely.", "next": 6 },
          { "text": "Power corrupts.", outcomes: [11] } // Outcome: You Escape the Palace
        ]
      },
      {
        "text": "What is the most important ingredient in transformation?",
        "options": [
          { "text": "Intent.", "next": 7 },
          { "text": "Sacrifice.", "next": 8 },
          { "text": "Time.", "next": 6 },
          { "text": "Faith.", outcomes: [12]} // Outcome: You are Dead
        ]
      },
      {
        "text": "Can the elixir of life be created?",
        "options": [
          { "text": "Yes.", "next": 8 },
          { "text": "No.", "next": 9 },
          { "text": "Only with the Philosopher’s Stone.", outcomes: [13] }, // Outcome: You become a Vampire serving Dracula
          { "text": "It is a myth.", outcomes: [14]  } // Outcome: You are to live in the palace as Dracula's servant for eternity
        ]
      },
      {
        "text": "Would you trade your soul for eternal knowledge?",
        "options": [
          { "text": "Yes.", "next": 8 },
          { "text": "No.", "next": 9 },
          { "text": "Knowledge is worth any price.",outcomes: [14] }, // Outcome: You are to live in the palace as Dracula's servant for eternity
          { "text": "A soul is more valuable than knowledge.", outcomes: [12] } // Outcome: You are Dead
        ]
      },
      {
        "text": "Is knowledge more powerful than wealth?",
        "options": [
          { "text": "Yes.",outcomes: [11]  }, // Outcome: You Escape the Palace
          { "text": "No.",  outcomes: [13] } ,// Outcome: You become a Vampire serving Dracula
          { "text": "They are equal.",outcomes: [14]  }, // Outcome: You are to live in the palace as Dracula's servant for eternity
          { "text": "I don’t know.",outcomes: [12]} // Outcome: You are Dead
        ]
      },
      {
        "text": "Does alchemy require sacrifice?",
        "options": [
          { "text": "Yes.", outcomes: [13] }, // Outcome: You become a Vampire serving Dracula
          { "text": "No.", outcomes: [12] }, // Outcome: You are Dead
          { "text": "The sacrifice is hidden.", outcomes: [11]  }, // Outcome: You Escape the Palace
          { "text": "I’m not sure.",outcomes: [14] } // Outcome: You are to live in the palace as Dracula's servant for eternity
        ]
      }
    ]
  },
//path20
  {
    "title": "The Timekeeper’s Choice",
    "description": "Explore the intricate threads of time with the Timekeeper. Engage with profound questions about fate and free will. Can you master the flow of time, or will you be swept away by its currents?.",
    "questions": [
      {
        "text": "Is time a friend or a foe?",
        "options": [
          { "text": "A friend.", "next": 1 },
          { "text": "A foe.", "next": 2 },
          { "text": "Both.", "next": 3 },
          { "text": "Time is irrelevant.",outcomes: [11] } // Outcome: You Escape the Palace
        ]
      },
      {
        "text": "Do you believe in fate?",
        "options": [
          { "text": "Yes.", "next": 4 },
          { "text": "No.", "next": 5 },
          { "text": "Only sometimes.", "next": 2 },
          { "text": "Fate is just an illusion.",outcomes: [12] } // Outcome: You are Dead
        ]
      },
      {
        "text": "Can the past be changed?",
        "options": [
          { "text": "Yes.", "next": 4 },
          { "text": "No.", "next": 6 },
          { "text": "Only in dreams.", "next": 5 },
          { "text": "The past shapes the future.", outcomes: [11] } // Outcome: You Escape the Palace
        ]
      },
      {
        "text": "Is the present a gift or a burden?",
        "options": [
          { "text": "A gift.", "next": 5 },
          { "text": "A burden.", "next": 7 },
          { "text": "Both.", "next": 4 },
          { "text": "It depends on perspective.",outcomes: [12] } // Outcome: You are Dead
        ]
      },
      {
        "text": "Would you sacrifice the present for a better future?",
        "options": [
          { "text": "Yes.", "next": 5 },
          { "text": "No.", "next": 8 },
          { "text": "Only if it’s certain.", "next": 6 },
          { "text": "The present is all I have.",outcomes: [11] } // Outcome: You Escape the Palace
        ]
      },
      {
        "text": "Can you learn from mistakes made in time?",
        "options": [
          { "text": "Yes.", "next": 7 },
          { "text": "No.", "next": 8 },
          { "text": "Only if you acknowledge them.", "next": 6 },
          { "text": "Mistakes are meant to be repeated.",outcomes: [12] } // Outcome: You are Dead
        ]
      },
      {
        "text": "Is there wisdom in waiting?",
        "options": [
          { "text": "Yes.", "next": 8 },
          { "text": "No.", "next": 9 },
          { "text": "Sometimes.",  outcomes: [13]}, // Outcome: You become a Vampire serving Dracula
          { "text": "Waiting is a waste of time.", outcomes: [14] } // Outcome: You are to live in the palace as Dracula's servant for eternity
        ]
      },
      {
        "text": "Would you want to know your future?",
        "options": [
          { "text": "Yes.", "next": 9 },
          { "text": "No.",  outcomes: [13] }, // Outcome: You become a Vampire serving Dracula
          { "text": "Only if it’s good.", outcomes: [14]  }, // Outcome: You are to live in the palace as Dracula's servant for eternity
          { "text": "Knowing the future is dangerous.", outcomes: [12]} // Outcome: You are Dead
        ]
      },
      {
        "text": "Is time a circle or a line?",
        "options": [
          { "text": "A circle.", outcomes: [11]}, // Outcome: You Escape the Palace
          { "text": "A line.",outcomes: [13]  }, // Outcome: You become a Vampire serving Dracula
          { "text": "It depends on perception.",outcomes: [14]  }, // Outcome: You are to live in the palace as Dracula's servant for eternity
          { "text": "Time is a construct.", outcomes: [12] } // Outcome: You are Dead
        ]
      },
      {
        "text": "Can time heal all wounds?",
        "options": [
          { "text": "Yes.", outcomes: [13] }, // Outcome: You become a Vampire serving Dracula
          { "text": "No.", outcomes: [12]}, // Outcome: You are Dead
          { "text": "Only if you let it.",outcomes: [11] }, // Outcome: You Escape the Palace
          { "text": "Time can also deepen them.", outcomes: [14]  } // Outcome: You are to live in the palace as Dracula's servant for eternity
        ]
      }
    ]
  },
  //path-21
  {
    "title": "The Visionary’s Test",
    "description": "Dare to see beyond the present. Can your foresight guide you through Dracula’s riddles of destiny and time, or will you be lost to the shadows of the unknown? Only visionaries will pass this test.",
    "questions": [
      {
        "text": "Can one person change the world?",
        "options": [
          { "text": "Yes", "next": 1 },
          { "text": "No", "next": 2 },
          { "text": "It depends on their vision", "next": 3 },
          { "text": "The world cannot be changed",outcomes: [11] } // You escape the palace
        ]
      },
      {
        "text": "What is more powerful: an idea or action?",
        "options": [
          { "text": "An idea", "next": 3 },
          { "text": "Action", "next": 4 },
          { "text": "They are inseparable", "next": 2 },
          { "text": "Neither has real power", outcomes: [12]} // You are dead
        ]
      },
      {
        "text": "Is the pursuit of perfection noble?",
        "options": [
          { "text": "Yes", "next": 5 },
          { "text": "No", "next": 6 },
          { "text": "Only if it's achievable", "next": 4 },
          { "text": "Perfection is a myth",outcomes: [11]} // You escape the palace
        ]
      },
      {
        "text": "Can chaos lead to creation?",
        "options": [
          { "text": "Yes", "next": 5 },
          { "text": "No", "next": 7 },
          { "text": "Sometimes", "next": 6 },
          { "text": "Chaos only destroys", outcomes: [12] } // You are dead
        ]
      },
      {
        "text": "Is it better to inspire or to lead?",
        "options": [
          { "text": "Inspire", "next": 6 },
          { "text": "Lead", "next": 8 },
          { "text": "Both are essential", "next": 5 },
          { "text": "Neither", outcomes: [11]} // You escape the palace
        ]
      },
      {
        "text": "Can power ever be shared?",
        "options": [
          { "text": "Yes", "next": 7 },
          { "text": "No", "next": 8 },
          { "text": "Only when the powerful allow it", outcomes: [13] }, // You become a vampire serving Dracula
          { "text": "Shared power always leads to ruin", outcomes: [12] } // You are dead
        ]
      },
      {
        "text": "Do you believe in destiny?",
        "options": [
          { "text": "Yes", "next": 8 },
          { "text": "No", "next": 9 },
          { "text": "Only when it suits me",outcomes: [14]  } ,// You are to live in the palace as Dracula's servant for eternity
          { "text": "Destiny is a cage", outcomes: [11]} // You escape the palace
        ]
      },
      {
        "text": "Is freedom more important than security?",
        "options": [
          { "text": "Yes", "next": 9 },
          { "text": "No",outcomes: [13] }, // You become a vampire serving Dracula
          { "text": "Only if you know how to use it", outcomes: [14]  }, // You are to live in the palace as Dracula's servant for eternity
          { "text": "Freedom is an illusion",outcomes: [12] } // You are dead
        ]
      },
      {
        "text": "Is it better to know or to believe?",
        "options": [
          { "text": "Know",  outcomes: [11]}, // You escape the palace
          { "text": "Believe",outcomes: [13]}, // You become a vampire serving Dracula
          { "text": "Neither; both are dangerous", outcomes: [14]  }, // You are to live in the palace as Dracula's servant for eternity
          { "text": "Ignorance is bliss", outcomes: [12]}, // You are dead
        ]
      },
      {
        "text": "What's more important: vision or perseverance?",
        "options": [
          { "text": "Vision", outcomes: [13]  }, // You become a vampire serving Dracula
          { "text": "Perseverance", outcomes: [11] }, // You escape the palace
          { "text": "Neither without the other", outcomes: [14]  }, // You are to live in the palace as Dracula's servant for eternity
          { "text": "Both are futile",outcomes: [12] } // You are dead
        ]
      }
    ]
  },
//path-22  
  {
    "title": "The Monarch’s Trial",
    "description": "Power, rule, and responsibility—do you have what it takes to rule, or will you crumble under the weight of leadership? The choices you make will determine your legacy. Enter Dracula’s domain and prove your worth as a monarch.",
    "questions": [
      {
        "text": "What is the true measure of a leader?",
        "options": [
          { "text": "Their legacy.", "next": 1 },
          { "text": "Their strength.", "next": 2 },
          { "text": "Their wisdom.", "next": 3 },
          { "text": "Their wealth.", outcomes: [11] } // Outcome: You Escape the Palace
        ]
      },
      {
        "text": "Can power exist without corruption?",
        "options": [
          { "text": "Yes.", "next": 4 },
          { "text": "No.", "next": 2 },
          { "text": "It depends on the leader.", "next": 3 },
          { "text": "All power corrupts.", outcomes: [12] } // Outcome: You are Dead
        ]
      },
      {
        "text": "Is it better to be feared or loved?",
        "options": [
          { "text": "Feared.", "next": 4 },
          { "text": "Loved.", "next": 5 },
          { "text": "Both.", "next": 3 },
          { "text": "Neither.", outcomes: [11] } // Outcome: You Escape the Palace
        ]
      },
      {
        "text": "Can peace be achieved without sacrifice?",
        "options": [
          { "text": "Yes.", "next": 5 },
          { "text": "No.", "next": 6 },
          { "text": "Peace requires blood.", "next": 4 },
          { "text": "Peace is unattainable.", outcomes: [12]} // Outcome: You are Dead
        ]
      },
      {
        "text": "What is the purpose of war?",
        "options": [
          { "text": "To conquer.", "next": 5 },
          { "text": "To defend.", "next": 6 },
          { "text": "To restore balance.", "next": 7 },
          { "text": "War has no purpose.", outcomes: [11] } // Outcome: You Escape the Palace
        ]
      },
      {
        "text": "Can a kingdom survive without its king?",
        "options": [
          { "text": "Yes.", "next": 7 },
          { "text": "No.", "next": 8 },
          { "text": "Only if the people are strong.", outcomes: [14]  } ,// Outcome: You are to live in the palace as Dracula’s servant for eternity
          { "text": "The king is the kingdom.",outcomes: [12]} // Outcome: You are Dead
        ]
      },
      {
        "text": "Is loyalty more important than ambition?",
        "options": [
          { "text": "Yes.", "next": 8 },
          { "text": "No.", "next": 9 },
          { "text": "Ambition drives progress.", outcomes: [14]  } ,// Outcome: You are to live in the palace as Dracula’s servant for eternity
          { "text": "Loyalty is a weakness.", outcomes: [11] } // Outcome: You Escape the Palace
        ]
      },
      {
        "text": "Can a ruler be just and merciful?",
        "options": [
          { "text": "Yes.", "next": 9 },
          { "text": "No.",outcomes: [13]  },// Outcome: You become a Vampire serving Dracula
          { "text": "Justice requires cruelty.",outcomes: [14]  }, // Outcome: You are to live in the palace as Dracula’s servant for eternity
          { "text": "Mercy is for the weak.", outcomes: [12]} // Outcome: You are Dead
        ]
      },
      {
        "text": "What is more important: law or order?",
        "options": [
          { "text": "Law.", outcomes: [11]} ,// Outcome: You Escape the Palace
          { "text": "Order.",outcomes: [13]  }, // Outcome: You become a Vampire serving Dracula
          { "text": "They are one and the same.",outcomes: [14] }, // Outcome: You are to live in the palace as Dracula’s servant for eternity
          { "text": "Both are flawed.", outcomes: [12] } // Outcome: You are Dead
        ]
      },
      {
        "text": "Can tyranny ever be justified?",
        "options": [
          { "text": "Yes.", outcomes: [13] }, // Outcome: You become a Vampire serving Dracula
          { "text": "No.", outcomes: [11] } ,// Outcome: You Escape the Palace
          { "text": "Only in times of crisis.", outcomes: [14] }, // Outcome: You are to live in the palace as Dracula’s servant for eternity
          { "text": "Tyranny is always evil.", outcomes: [12] } // Outcome: You are Dead
        ]
      }
    ]
  },
//path23
  {
    "title": "The Strategist’s Game",
    "description": "Every move counts in this dangerous game of wit and strategy. Can you outthink Dracula and survive the endless twists of battle, or will your plan lead you into eternal servitude? Choose wisely, or be outmaneuvered.",
    "questions": [
      {
        "text": "What is the most important asset in a war?",
        "options": [
          { "text": "An army.", "next": 1 },
          { "text": "A leader.", "next": 2 },
          { "text": "A strategy.", "next": 3 },
          { "text": "Resources.", outcomes: [11]} // Outcome: You Escape the Palace
        ]
      },
      {
        "text": "Can a battle be won with sheer numbers?",
        "options": [
          { "text": "Yes.", "next": 4 },
          { "text": "No.", "next": 2 },
          { "text": "It depends on the commander.", "next": 3 },
          { "text": "Numbers don’t matter; skill does.", outcomes: [12] } // Outcome: You are Dead
        ]
      },
      {
        "text": "Is deception a valid tactic?",
        "options": [
          { "text": "Yes.", "next": 4 },
          { "text": "No.", "next": 5 },
          { "text": "Only when necessary.", "next": 3 },
          { "text": "Deception is dishonorable.",outcomes: [11] } // Outcome: You Escape the Palace
        ]
      },
      {
        "text": "Can a victory be won without sacrifice?",
        "options": [
          { "text": "Yes.", "next": 5 },
          { "text": "No.", "next": 6 },
          { "text": "Only if the plan is perfect.", "next": 4 },
          { "text": "Sacrifice is inevitable.", outcomes: [12] } // Outcome: You are Dead
        ]
      },
      {
        "text": "What is the key to defeating a stronger enemy?",
        "options": [
          { "text": "Outsmart them.", "next": 5 },
          { "text": "Divide their forces.", "next": 7 },
          { "text": "Weaken their resolve.", "next": 6 },
          { "text": "There is no key.", outcomes: [11] } // Outcome: You Escape the Palace
        ]
      },
      {
        "text": "Is loyalty more valuable than competence?",
        "options": [
          { "text": "Yes.", "next": 7 },
          { "text": "No.", "next": 8 },
          { "text": "It depends on the situation.",outcomes: [14]  } ,// Outcome: You are to live in the palace as Dracula’s servant for eternity
          { "text": "Competence trumps all.",outcomes: [12] } // Outcome: You are Dead
        ]
      },
      {
        "text": "Can peace be maintained through fear?",
        "options": [
          { "text": "Yes.", "next": 8 },
          { "text": "No.", "next": 9 },
          { "text": "Fear is only temporary.",outcomes: [14]  } ,// Outcome: You are to live in the palace as Dracula’s servant for eternity
          { "text": "Fear leads to rebellion.", outcomes: [11]} // Outcome: You Escape the Palace
        ]
      },
      {
        "text": "Is a swift decision always the best?",
        "options": [
          { "text": "Yes.", "next": 9 },
          { "text": "No.", outcomes: [13] } ,// Outcome: You become a Vampire serving Dracula
          { "text": "Only if time is scarce.",outcomes: [14] } ,// Outcome: You are to live in the palace as Dracula’s servant for eternity
          { "text": "Rushed decisions lead to failure.", outcomes: [12]} // Outcome: You are Dead
        ]
      },
      {
        "text": "What is more important in war: trust or control?",
        "options": [
          { "text": "Trust.",outcomes: [11] }, // Outcome: You Escape the Palace
          { "text": "Control.",outcomes: [13]  }, // Outcome: You become a Vampire serving Dracula
          { "text": "A balance of both.", outcomes: [14]  }, // Outcome: You are to live in the palace as Dracula’s servant for eternity
          { "text": "Neither.", outcomes: [12] } // Outcome: You are Dead
        ]
      },
      {
        "text": "Can victory be achieved without breaking the rules?",
        "options": [
          { "text": "Yes.",outcomes: [11] }, // Outcome: You Escape the Palace
          { "text": "No.", outcomes: [13] }, // Outcome: You become a Vampire serving Dracula
          { "text": "The rules are meant to be broken.", outcomes: [14]  }, // Outcome: You are to live in the palace as Dracula’s servant for eternity
          { "text": "Rules exist to be exploited.", outcomes: [12] } // Outcome: You are Dead
        ]
      }
    ]
  },
//path24
  {
    "title": "The Oracle’s Revelation",
    "description": "What is more dangerous: knowledge or ignorance? In this path, you must navigate the fine line between foresight and blindness. Trust your instincts, but beware—too much insight may cost you more than your life..",
    "questions": [
      {
        "text": "Can the future be predicted?",
        "options": [
          { "text": "Yes.", "next": 1 },
          { "text": "No.", "next": 2 },
          { "text": "Only through great insight.", "next": 3 },
          { "text": "The future is unknowable.", outcomes: [11] } // Outcome: You Escape the Palace
        ]
      },
      {
        "text": "Do you trust your intuition?",
        "options": [
          { "text": "Yes.", "next": 4 },
          { "text": "No.", "next": 2 },
          { "text": "Sometimes.", "next": 3 },
          { "text": "Intuition is unreliable.", outcomes: [12] } // Outcome: You are Dead
        ]
      },
      {
        "text": "Is knowledge more important than faith?",
        "options": [
          { "text": "Yes.", "next": 4 },
          { "text": "No.", "next": 5 },
          { "text": "Both are equally important.", "next": 3 },
          { "text": "Faith alone is enough.", outcomes: [11] } // Outcome: You Escape the Palace
        ]
      },
      {
        "text": "Can destiny be altered?",
        "options": [
          { "text": "Yes.", "next": 5 },
          { "text": "No.", "next": 6 },
          { "text": "Only through great effort.", "next": 4 },
          { "text": "Destiny is set in stone.", outcomes: [12] } // Outcome: You are Dead
        ]
      },
      {
        "text": "What is the cost of knowing too much?",
        "options": [
          { "text": "Your peace.", "next": 5 },
          { "text": "Your soul.", "next": 7 },
          { "text": "Your future.", "next": 6 },
          { "text": "There is no cost.",outcomes: [11] } // Outcome: You Escape the Palace
        ]
      },
      {
        "text": "Is it better to ask questions or seek answers?",
        "options": [
          { "text": "Ask questions.", "next": 7 },
          { "text": "Seek answers.", "next": 8 },
          { "text": "Both lead to knowledge.",outcomes: [14]  }, // Outcome: You are to live in the palace as Dracula’s servant for eternity
          { "text": "Neither matters.", outcomes: [12] } // Outcome: You are Dead
        ]
      },
      {
        "text": "Can truth ever be subjective?",
        "options": [
          { "text": "Yes.", "next": 8 },
          { "text": "No.", "next": 9 },
          { "text": "It depends on perception.",outcomes: [14]  }, // Outcome: You are to live in the palace as Dracula’s servant for eternity
          { "text": "Truth is absolute.",outcomes: [11]} // Outcome: You Escape the Palace
        ]
      },
      {
        "text": "Is ignorance truly bliss?",
        "options": [
          { "text": "Yes.", "next": 9 },
          { "text": "No.", outcomes: [13]  }, // Outcome: You become a Vampire serving Dracula
          { "text": "Bliss only for the foolish.", outcomes: [14] } ,// Outcome: You are to live in the palace as Dracula’s servant for eternity
          { "text": "Ignorance is dangerous.",outcomes: [12]} // Outcome: You are Dead
        ]
      },
      {
        "text": "Is it better to see the future or control the present?",
        "options": [
          { "text": "See the future.",outcomes: [11] }, // Outcome: You Escape the Palace
          { "text": "Control the present.",outcomes: [13]  }, // Outcome: You become a Vampire serving Dracula
          { "text": "Balance both.",outcomes: [14]  }, // Outcome: You are to live in the palace as Dracula’s servant for eternity
          { "text": "Neither matters.",outcomes: [12] } // Outcome: You are Dead
        ]
      },
      {
        "text": "Can you ever truly understand the future?",
        "options": [
          { "text": "Yes.", outcomes: [11] }, // Outcome: You Escape the Palace
          { "text": "No.", outcomes: [13]  }, // Outcome: You become a Vampire serving Dracula
          { "text": "Only in glimpses.",outcomes: [14] }, // Outcome: You are to live in the palace as Dracula’s servant for eternity
          { "text": "The future is beyond understanding.", outcomes: [12] } // Outcome: You are Dead
        ]
      }
    ]
  },
//path25
  {
    "title": "The Diplomat’s Decision",
    "description": "Negotiation is an art, and in this trial, you must walk the fine line between peace and destruction. Can you make the right choices to avoid conflict, or will your words lead to ruin? The stakes are high—one wrong move could seal your fate..",
    "questions": [
      {
        "text": "Can peace be negotiated with an enemy?",
        "options": [
          { "text": "Yes.", "next": 1 },
          { "text": "No.", "next": 2 },
          { "text": "Only with mutual respect.", "next": 3 },
          { "text": "Peace is impossible with enemies.",outcomes: [11] } // Outcome: You Escape the Palace
        ]
      },
      {
        "text": "Is compromise a sign of weakness?",
        "options": [
          { "text": "Yes.", "next": 4 },
          { "text": "No.", "next": 2 },
          { "text": "It depends on the situation.", "next": 3 },
          { "text": "Compromise is a strength.",outcomes: [12]} // Outcome: You are Dead
        ]
      },
      {
        "text": "Is it better to concede or risk everything?",
        "options": [
          { "text": "Risk everything.", "next": 4 },
          { "text": "Concede when necessary.", "next": 5 },
          { "text": "It depends on the stakes.", "next": 3 },
          { "text": "Never concede.",outcomes: [11] } // Outcome: You Escape the Palace
        ]
      },
      {
        "text": "Can loyalty be bought?",
        "options": [
          { "text": "Yes.", "next": 5 },
          { "text": "No.", "next": 6 },
          { "text": "It depends on the person.", "next": 4 },
          { "text": "True loyalty cannot.", outcomes: [12]} // Outcome: You are Dead
        ]
      },
      {
        "text": "What is the price of peace?",
        "options": [
          { "text": "Freedom.", "next": 5 },
          { "text": "Sacrifice.", "next": 7 },
          { "text": "Power.", "next": 6 },
          { "text": "There is no price.",outcomes: [11]} // Outcome: You Escape the Palace
        ]
      },
      {
        "text": "Is it better to lead or follow?",
        "options": [
          { "text": "Lead.", "next": 7 },
          { "text": "Follow.", "next": 8 },
          { "text": "Both at different times.",outcomes: [14]  }, // Outcome: You are to live in the palace as Dracula’s servant for eternity
          { "text": "Neither is necessary.",outcomes: [12] } // Outcome: You are Dead
        ]
      },
      {
        "text": "Can trust be rebuilt after betrayal?",
        "options": [
          { "text": "Yes.", "next": 8 },
          { "text": "No.", "next": 9 },
          { "text": "It takes time.", outcomes: [14] }, // Outcome: You are to live in the palace as Dracula’s servant for eternity
          { "text": "Trust is broken forever.",outcomes: [11] } // Outcome: You Escape the Palace
        ]
      },
      {
        "text": "Is diplomacy always preferable to war?",
        "options": [
          { "text": "Yes.", "next": 9 },
          { "text": "No.", outcomes: [13]  }, // Outcome: You become a Vampire serving Dracula
          { "text": "Only if the enemy is willing.", outcomes: [14]  }, // Outcome: You are to live in the palace as Dracula’s servant for eternity
          { "text": "War is sometimes necessary.", outcomes: [12] } // Outcome: You are Dead
        ]
      },
      {
        "text": "Is it better to win a battle or win the war?",
        "options": [
          { "text": "Win the war.", outcomes: [11] } ,// Outcome: You Escape the Palace
          { "text": "Win the battle.",outcomes: [13]  }, // Outcome: You become a Vampire serving Dracula
          { "text": "Both matter equally.", outcomes: [14]  } ,// Outcome: You are to live in the palace as Dracula’s servant for eternity
          { "text": "Winning either is enough.", outcomes: [12] } // Outcome: You are Dead
        ]
      },
      {
        "text": "Can negotiation ever be a form of manipulation?",
        "options": [
          { "text": "Yes.",outcomes: [11] }, // Outcome: You Escape the Palace
          { "text": "No.",outcomes: [13] }, // Outcome: You become a Vampire serving Dracula
          { "text": "Only if used unethically.",outcomes: [14]  }, // Outcome: You are to live in the palace as Dracula’s servant for eternity
          { "text": "Negotiation is always neutral.",outcomes: [12] } // Outcome: You are Dead
        ]
      }
    ]
  }
  
];

function App() {
  const [selectedPathIndex, setSelectedPathIndex] = useState(null); // Initialize as null
  const [currentComponent, setCurrentComponent] = useState('animation');
  const [showModal, setShowModal] = useState(false); // Show modal only when needed

  useEffect(() => {
    const timer = setTimeout(() => {
      setCurrentComponent('introduction');
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  const handleSkip = () => {
    setCurrentComponent('allPaths'); // Show Allpath component after skipping
  };

  const handleModalClose = () => {
    setShowModal(false);  // Hide the modal when skip is clicked
  };

  const handleSelectPath = (index) => {
    setSelectedPathIndex(index); // Set the selected path index
    setCurrentComponent('path'); // Move to the path component
    setShowModal(true); // Show modal with the description
  };

  const handlePathEnd = () => {
    if (selectedPathIndex < paths.length - 1) {
      setSelectedPathIndex(selectedPathIndex + 1);
      setShowModal(true); // Show modal for next path
    } else {
      alert("All paths completed!");
      setSelectedPathIndex(0); // Reset to the first path (optional)
    }
  };

  const toggleModal = () => {
    setShowModal(prev => !prev);  // Toggle modal visibility
  };




  // New function to return to PathList (Allpath component)
  const handleReturnToPathList = () => {
    setCurrentComponent('allPaths');
  };

  

  return (
    <div className="background">
      <div className="App">
        {currentComponent === 'animation' && <DraculaAnimation />}
        {currentComponent === 'introduction' && <Introduction onSkip={handleSkip} />}
        {currentComponent === 'allPaths' && (
          <Allpath paths={paths} onSelectPath={handleSelectPath} />
        )}
        {currentComponent === 'path' && (
          <>
            {/* Show the modal if the state is true */}
            {showModal && selectedPathIndex !== null && (
              <Modal
                title={paths[selectedPathIndex].title}
                description={paths[selectedPathIndex].description}
                onClose={handleModalClose}
              />
            )}
            {/* Path component with an option to read the description */}
            <Path
              key={selectedPathIndex}
              pathData={paths[selectedPathIndex]}
              onPathEnd={handlePathEnd}
              onReadAboutPath={toggleModal}  // Pass toggle function to Path
               onReturnToPathList={handleReturnToPathList} 
            />
          </>
        )}
      </div>
    </div>
  );
};

export default App;


import React from 'react';
import "../allPaths/Allpath.css";

const Allpath = ({ paths, onSelectPath }) => {
    console.log("Allpath.js - Received paths:", paths);
    return (
        <div className="questions-container">
            <h2 id='headAll'>Select a Path to Start</h2>
            <div className="path-grid">
                {paths && paths.length > 0 ? (
                    paths.map((path, index) => (
                        <button 
                            key={index} 
                            onClick={() => onSelectPath(index)} 
                            className="path-button"
                        >
                            {path.title}
                        </button>
                    ))
                ) : (
                    <p>No paths available</p>
                )}
            </div>
        </div>
    );
};



export default Allpath;

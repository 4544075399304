// src/components/DraculaAnimation.js
import React, { useEffect } from "react";
import "./DraculaAnimation.css";

const ThunderstormEffect = () => {
  useEffect(() => {
    
    makeItRain();   
  }, []);

  const makeItRain = () => {
    const rainFrontRow = document.querySelector(".rain.front-row");
    const rainBackRow = document.querySelector(".rain.back-row");
    rainFrontRow.innerHTML = "";
    rainBackRow.innerHTML = "";

    let increment = 0;
    let drops = "";
    let backDrops = "";

    while (increment < 500) {
      const randoHundo = Math.floor(Math.random() * (98 - 1 + 1) + 1);
      const randoFiver = Math.floor(Math.random() * (5 - 2 + 1) + 2);
      increment += randoFiver;

      drops += `
        <div class="drop" style="left: ${increment}%; bottom: ${randoFiver + randoFiver - 1 + 100}%; animation-delay: 0.${randoHundo}s; animation-duration: 0.2${randoHundo}s;">
          <div class="stem" style="animation-delay: 0.${randoHundo}s; animation-duration: 0.2${randoHundo}s;"></div>
          <div class="splat" style="animation-delay: 0.${randoHundo}s; animation-duration: 0.2${randoHundo}s;"></div>
        </div>`;

      backDrops += `
        <div class="drop" style="right: ${increment}%; bottom: ${randoFiver + randoFiver - 1 + 100}%; animation-delay: 0.${randoHundo}s; animation-duration: 0.2${randoHundo}s;">
          <div class="stem" style="animation-delay: 0.${randoHundo}s; animation-duration: 0.2${randoHundo}s;"></div>
          <div class="splat" style="animation-delay: 0.${randoHundo}s; animation-duration: 0.2${randoHundo}s;"></div>
        </div>`;
    }

    rainFrontRow.innerHTML = drops;
    rainBackRow.innerHTML = backDrops;
  };

  return (
    <div className="rain-container">
      <div className="rain front-row"></div>
      <div className="rain back-row"></div>

      {/* Fog effect */}
      <div className="fog"></div>

      {/* Lightning flash */}
      <div className="lightning-flash"></div>

      {/* Logo and Text with Dripping Effect */}
      <div className="logo-container">
        <h1 id="dracula-text">
          {"Dracula   Asks".split("").map((letter, index) => (
            <span key={index} className="letter">
              {letter}
              <div className="drip"></div>
            </span>
          ))}
        </h1>
      </div>
    </div>
  );
};

export default ThunderstormEffect;

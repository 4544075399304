
import React from "react";
import "../model/Model.css"; // Keep the same CSS file for external styling

const Modal = ({ title, description, onClose }) => {
  return (
    <div className="modal-overlay">
      <div className="modal-content enhanced-modal">
        <h2 className="modal-title">
          🚀 {title} 🚀
        </h2>
        <p className="modal-description">{description}</p>
        <button className="challenge-button" onClick={onClose}>
          🕹️ Start the Challenge 🕹️
        </button>
      </div>
    </div>
  );
};

export default Modal;

import React from 'react';
import './privacyPolicy.css';

const PrivacyPolicy = ({ onClose }) => {
  return (
    <div className="privacy-policy">
      <h2 style={{
         fontSize: '17px',
         color: 'black',         
      }}>Privacy Policy</h2>
      <p><strong>Last Updated: October 18, 2024</strong></p>
      <h3>Welcome to DraculaAsks</h3>
      <p>
        DraculaAsks ("we," "us," or "our") values your privacy and is committed to protecting your personal information. 
        This Privacy Policy explains how we collect, use, and safeguard your information when you visit draculasks.com.
      </p>

      <h3>Information We Collect</h3>
      <h3>Personal Information</h3>
      <ul>
        <li>Email address (if you choose to provide it)</li>
        <li>Username or display name</li>
        <li>Game progress and scores</li>
        <li>Country/Region of access</li>
      </ul>

      <h3>Automatically Collected Information</h3>
      <ul>
        <li>IP address</li>
        <li>Browser type</li>
        <li>Device information</li>
        <li>Operating system</li>
        <li>Date and time of visits</li>
        <li>Pages viewed</li>
        <li>Time spent on site</li>
        <li>Referring website addresses</li>
      </ul>

      <h3>How We Use Your Information</h3>
      <p>We use collected information to:</p>
      <ul>
        <li>Maintain and improve DraculaAsks</li>
        <li>Track game progress and scores</li>
        <li>Provide customer support</li>
        <li>Send updates about the game (if you opt-in)</li>
        <li>Analyze site usage to enhance user experience</li>
        <li>Prevent fraud and ensure site security</li>
      </ul>

      <h2>Data Storage and Security</h2>
      <p>
        We implement industry-standard security measures to protect your information. However, no internet 
        transmission is completely secure, and we cannot guarantee absolute security.
      </p>

      <h3>Cookies and Tracking</h3>
      <p>We use cookies to:</p>
      <ul>
        <li>Remember your preferences</li>
        <li>Track game progress</li>
        <li>Analyze site traffic</li>
        <li>Improve user experience</li>
      </ul>
      <p>You can control cookie settings through your browser preferences.</p>

      <h3>International Users</h3>
      <p>
        DraculaAsks welcomes users from various countries. By using our site, you consent to the 
        transfer of your information to our servers in accordance with this policy.
      </p>

      <h3>Children's Privacy</h3>
      <p>
        DraculaAsks is intended for users aged 18 and older. We do not knowingly collect information from children 
        under 18.
      </p>

      <h3>Changes to Privacy Policy</h3>
      <p>
        We may update this policy periodically. Changes will be posted on this page with an updated revision date.
      </p>

      <h3>Contact Us</h3>
      <p>
        For questions about this Privacy Policy, please contact us at: <a href="mailto:info@draculaasks.com">info@draculaasks.com</a>
      </p>

      <button className="close-button" onClick={onClose}>X</button>
    </div>
  );
};

export default PrivacyPolicy;

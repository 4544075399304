

import React, { useState } from 'react';
import './ContactUs.css';

const ContactUs = ({ onClose, onSubmit = () => {} }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [statusMessage, setStatusMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check for empty fields
    if (!name || !email || !message) {
      setError('Please fill in all fields.');
      
      return;
    }

    setError('');
    setStatusMessage("Sending...");
    
    // Create form data
    const formData = new FormData();
    formData.append("access_key", "f2df4ad1-0d51-4e32-ba1d-e00e5ba10d61");
    formData.append("name", name);
    formData.append("email", email);
    formData.append("message", message);

    // Submit form data to Web3Forms
    try {
      const response = await fetch("https://api.web3forms.com/submit", {
        method: "POST",
        body: formData
      });
      const data = await response.json();

      if (data.success) {
        setSuccess(true);
        setStatusMessage("Message sent successfully!");
        setName('');
        setEmail('');
        setMessage('');
        onSubmit();
      } else {
        setError("Error sending message. Please try again later.");
        setStatusMessage(data.message || "Error occurred");
      }
    } catch (error) {
      setError("Network error. Please try again later.");
      console.error("Error:", error);
    }

    // Hide success message after 3 seconds
    setTimeout(() => setSuccess(false), 3000);
  };

  return (
    <div className="contact-us-container">
      <h2>Contact Us</h2>
      {success && <p className="success-message">{statusMessage}</p>}
      {error && <p className="error-message">{error}</p>}
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="name">Name:</label>
          <input
            type="text"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
            className="input-field"
            name="name"
          />
        </div>
        <div className="form-group">
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            className="input-field"
            name="email"
          />
        </div>
        <div className="form-group">
          <label htmlFor="message">Message:</label>
          <textarea
            id="message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            required
            className="textarea-field"
            name="message"
          />
        </div>
        <button type="submit" className="submit-button">Send Message</button>
        <button type="button" onClick={onClose} className="cancel-button">
          Cancel
        </button>
      </form>
      {statusMessage && <p>{statusMessage}</p>}
    </div>
  );
};

export default ContactUs;

import React from 'react';
import './desclaimer.css';

const Disclaimer = ({ onClose }) => {
  return (
    <div className="disclaimer">
      <h2 style={{ fontSize: '17px', color: 'black' }}>Disclaimer</h2>
      <p><strong>Last Updated: October 18, 2024</strong></p>
      <h2>Website Usage Agreement</h2>
      <p>
        By accessing and using <strong>DraculaAsks</strong> (draculasks.com), you accept and agree to be bound by the terms of this disclaimer.
      </p>
      <h2>Content Disclaimer</h2>
      <p>
        The content on DraculaAsks is for entertainment purposes only. Questions, answers, and other content may include references to horror, supernatural, and Halloween themes. While we strive for accuracy, we make no guarantees about the completeness or reliability of site content. User-generated content does not necessarily reflect our views.
      </p>
      <h2>Music Attribution</h2>
      <p>
        The background music for "Into the Void" is created by Karl Casey @ White Bat Audio. This music is used under White Bat Audio's free-use policy. Source: YouTube - White Bat Audio. While this track is labeled as "No Copyright Music," users of DraculaAsks may not:
      </p>
      <ul>
        <li>Redistribute the music separately from our website.</li>
        <li>Claim ownership of the music.</li>
        <li>Use the music for commercial purposes outside of DraculaAsks without proper permission.</li>
      </ul>
      <p>All music rights remain with the original creator Karl Casey and White Bat Audio.</p>
      <h2>Intellectual Property</h2>
      <p>
        DraculaAsks is inspired by and refers to Dracula, a public domain character from Bram Stoker's novel published in 1897. We do not claim any ownership or copyright over the original Dracula character or story. Dracula-related content respects existing copyrights and falls under fair use where applicable. Users may not reproduce, distribute, or modify site content without permission.
      </p>
      <h2>Game Play and Scoring</h2>
      <p>
        Game scores and progress are recorded as accurately as possible. Technical issues may occasionally affect game performance. We reserve the right to modify game rules and scoring systems. User accounts may be suspended for violation of terms.
      </p>
      <h2>External Links</h2>
      <p>
        DraculaAsks may contain links to external websites. We are not responsible for external content or privacy practices. Users click external links at their own risk.
      </p>
      <h2>Technical Issues</h2>
      <p>
        Site access and gameplay may be interrupted for maintenance. We do not guarantee uninterrupted access to the site. Technical issues may affect game progress or scores.
      </p>
      <h2>Limitation of Liability</h2>
      <p>
        DraculaAsks and its operators shall not be liable for:
      </p>
      <ul>
        <li>Direct or indirect damages from site usage.</li>
        <li>Loss of data or game progress.</li>
        <li>Technical issues or service interruptions.</li>
        <li>User disputes or conflicts.</li>
      </ul>
      <h2>Medical Disclaimer</h2>
      <p>
        Content may include horror themes that some users find disturbing. Users with sensitivity to horror content should use discretion. We are not responsible for emotional responses to content.
      </p>
      <h2>Geographic Restrictions</h2>
      <p>
        While DraculaAsks welcomes international users, access may be restricted in certain jurisdictions. Users are responsible for complying with local laws.
      </p>
      <h2>Changes to Disclaimer</h2>
      <p>
        We reserve the right to modify this disclaimer at any time. Continued site use constitutes acceptance of updated terms.
      </p>
      <h2>Contact Information</h2>
      <p>
        For questions about this disclaimer, please contact: <a href="mailto:info@draculaasks.com">info@draculaasks.com</a>
      </p>

      <button className="close-button" onClick={onClose}>X</button>
    </div>
  );
};

export default Disclaimer;
